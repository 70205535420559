import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AlertSnackbar,
  AlertSnackbarNotificationProps,
} from "Components/Common/AlertSnackbar";
import CardPanel from "Components/Common/CardPanel";
import PageTemplate from "Components/Common/PageTemplate";
import ListingsGrid from "Components/Listings/ListingGrid";
import AboutMe from "Components/Profile/AboutMe";
import {
  fetchUserData,
  fetchUserPublicProfileData,
} from "Components/Profile/profileApiRequest";
import ProfileImage from "Components/Profile/ProfileImage";
import ProfileName from "Components/Profile/ProfileName";
import ProfileUrl from "Components/Profile/ProfileUrl";
import { ProfileDTO } from "dtos/ProfileDTO";
import { UserDTO } from "dtos/UserDTO";
import React, { useEffect, useState } from "react";
import { fetchWithJwt } from "utils/apiRequest";
import { getUserName } from "utils/authentication";

export default function ProfilePageSettings() {
  const [notification, setNotification] =
    useState<AlertSnackbarNotificationProps>({
      open: false,
    });
  const [user, setUser] = useState<UserDTO>();
  const [publicProfile, setPublicProfile] = useState<ProfileDTO>();

  useEffect(() => {
    fetchUserData(setUser);
    fetchUserPublicProfileData(setPublicProfile);
  }, []);

  const saveProfile = async () => {
    try {
      const response = await fetchWithJwt({
        endpoint: `/profile/${getUserName()}`,
        method: "post",
        body: JSON.stringify({
          ...publicProfile,
          profilePictureUrl: user?.profilePictureUrl,
          name: user?.name,
          isProfileLive: publicProfile?.isProfileLive,
        }),
      });
      await response.json();
      setNotification({
        open: true,
        message: "Successfully updated profile",
        severity: "success",
      });
    } catch (error) {
      setNotification({
        open: true,
        message: "Unable to update profile",
        severity: "error",
      });
    }
  };

  return (
    <PageTemplate>
      <AlertSnackbar
        notification={notification}
        setNotification={setNotification}
      />
      <Grid container spacing={2}>
        {/* Left side: Configure panel */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <CardPanel
            title={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Configure</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveProfile}
                >
                  <SaveIcon sx={{ marginRight: "10px" }} />
                  Save
                </Button>
              </Box>
            }
            sx={{ flexGrow: 1 }}
          >
            <Typography variant="body2" color="text.secondary">
              Configure your public profile to showcase and manage the Git
              repositories you are offering for sale. This allows potential
              buyers to view details about your repositories and make purchases
              directly from your profile.
            </Typography>

            <ProfileUrl username={getUserName()} />

            <Box display="inline-flex" alignItems="center" marginTop={2}>
              <Typography variant="body1">Make Profile Live:</Typography>
              <Switch
                checked={publicProfile?.isProfileLive}
                onChange={() =>
                  setPublicProfile({
                    ...publicProfile,
                    isProfileLive: !publicProfile.isProfileLive,
                  })
                }
                color="primary"
              />
              <Tooltip title="Making your profile live will allow users to purchase your repositories">
                <IconButton>
                  <InfoIcon color="info" />
                </IconButton>
              </Tooltip>
            </Box>

            <TextField
              label="About Me"
              value={publicProfile?.about || ""}
              onChange={(e) =>
                setPublicProfile({ ...publicProfile, about: e.target.value })
              }
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              sx={{ marginTop: 2 }}
            />
          </CardPanel>
        </Grid>

        {/* Right side: Profile preview */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <CardPanel title="" sx={{ flexGrow: 1 }}>
            <Stack spacing={2} alignItems="center">
              <ProfileImage src={user?.profilePictureUrl} sx={{ width: 250 }} />
              <ProfileName name={user?.name} variant="h4" sx={{}} />
              <AboutMe text={publicProfile?.about} />
              <ListingsGrid username={getUserName()} />
            </Stack>
          </CardPanel>
        </Grid>
      </Grid>
    </PageTemplate>
  );
}
