import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchUnauthenticated } from "utils/apiRequest";

interface FinishedProps {
  handleClose: () => void;
  intentId: string;
}

const Finished: React.FC<FinishedProps> = ({ handleClose, intentId }) => {
  const [purchaseStatus, setPurchaseStatus] = useState<string>("loading");
  const [loading, setLoading] = useState<boolean>(true);
  const [timeoutReached, setTimeoutReached] = useState<boolean>(false);

  const checkPurchaseStatus = () => {
    fetchUnauthenticated({
      endpoint: `/payments/checkPaymentStatus/${intentId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        setPurchaseStatus(data.purchaseStatus);
      })
      .catch(() => {
        setPurchaseStatus("error");
      });
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let interval: NodeJS.Timeout;

    // Poll for status every 2 seconds if the status is 'purchase_initiated'
    if (
      purchaseStatus === "purchase_initiated" ||
      purchaseStatus === "loading"
    ) {
      interval = setInterval(() => {
        checkPurchaseStatus();
      }, 2000);

      // Set a timeout of 30 seconds
      timeout = setTimeout(() => {
        setTimeoutReached(true);
        clearInterval(interval);
        setLoading(false);
      }, 30000);
    }

    return () => {
      clearInterval(interval); // Cleanup interval
      clearTimeout(timeout); // Cleanup timeout
    };
  }, [purchaseStatus]);

  // Set loading to false once status is no longer "purchase_initiated"
  useEffect(() => {
    if (
      purchaseStatus !== "purchase_initiated" &&
      purchaseStatus !== "loading"
    ) {
      setLoading(false);
    }
  }, [purchaseStatus]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : purchaseStatus === "completed" ? (
        <Typography variant="h6" color="success.main">
          Payment successful! Please check your email for the GitHub invite
          link.
        </Typography>
      ) : timeoutReached ? (
        <Typography variant="h6" color="error.main">
          There has been a problem adding you to the github repo, please contact
          support
        </Typography>
      ) : purchaseStatus === "error" ? (
        <Typography variant="h6" color="error.main">
          An unexpected error occurred. Please contact support.
        </Typography>
      ) : purchaseStatus === "payment_failed" ? (
        <Typography variant="h6" color="error.main">
          Payment failed. Please try again.
        </Typography>
      ) : (
        <Typography variant="h6">
          Processing your payment. Please wait...
        </Typography>
      )}

      <Button
        onClick={handleClose}
        sx={{ mt: 2 }}
        variant="contained"
        color="primary"
        disabled={loading}
      >
        Close
      </Button>
    </Box>
  );
};

export default Finished;
