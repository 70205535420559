import {
  CircularProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import CardPanel from "Components/Common/CardPanel";
import { PurchasesSalesDTO } from "dtos/apiResponses/PurchasesDTO";
import React, { useEffect, useState } from "react";
import { fetchWithJwt } from "utils/apiRequest";
import { formatUnixTimestamp } from "utils/stringUtils";

type Order = "asc" | "desc";

export const PurchasesTable = () => {
  const [purchases, setPurchases] = useState<PurchasesSalesDTO[]>([]);
  const [filteredPurchases, setFilteredPurchases] = useState<
    PurchasesSalesDTO[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] =
    useState<keyof PurchasesSalesDTO>("lastUpdated");
  const [sortOrder, setSortOrder] = useState<Order>("desc");
  const rowsPerPage = 10; // Show 10 rows per page

  useEffect(() => {
    const fetchPurchases = async () => {
      try {
        const response = await fetchWithJwt({
          endpoint: "/purchases/sales",
          method: "GET",
        });
        const data: PurchasesSalesDTO[] = await response.json();
        setPurchases(data);
        setFilteredPurchases(data); // Initially show all data
      } catch (err) {
        setError("Failed to load purchases.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchases();
  }, []);

  // Handle search across all fields
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);

    const filtered = purchases.filter((purchase) =>
      Object.values({
        buyerGitHubUsername: purchase.buyerGitHubUsername.toLowerCase(),
        repo: purchase.repo.toLowerCase(),
        stripeId: purchase.stripeId.toLowerCase(),
        price: (purchase.price / 100).toFixed(2), // Convert price to dollars
        purchaseStatus: purchase.purchaseStatus.toLowerCase(),
        applicationFee: (purchase.applicationFee / 100).toFixed(2), // Convert application fee to dollars
      }).some((field) => field.includes(value))
    );
    setFilteredPurchases(filtered);
    setCurrentPage(1); // Reset to the first page on search
  };

  // Handle pagination
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  // Handle sorting
  const handleSort = (column: keyof PurchasesSalesDTO) => {
    const isAsc = sortColumn === column && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortColumn(column);
  };

  // Sort the data based on the selected column and order
  const sortedRows = [...filteredPurchases].sort((a, b) => {
    if (!sortColumn) return 0; // If no column is selected, no sorting is applied

    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    if (typeof valueA === "number" && typeof valueB === "number") {
      return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
    } else {
      return sortOrder === "asc"
        ? String(valueA).localeCompare(String(valueB))
        : String(valueB).localeCompare(String(valueA));
    }
  });

  // Calculate displayed rows based on the current page
  const displayedRows = sortedRows.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;

  return (
    <CardPanel title="Sales">
      {/* Search Bar */}
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearch}
      />

      {/* Table */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === "buyerGitHubUsername"}
                  direction={
                    sortColumn === "buyerGitHubUsername" ? sortOrder : "asc"
                  }
                  onClick={() => handleSort("buyerGitHubUsername")}
                >
                  Buyer GitHub Username
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === "repo"}
                  direction={sortColumn === "repo" ? sortOrder : "asc"}
                  onClick={() => handleSort("repo")}
                >
                  Repo
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === "stripeId"}
                  direction={sortColumn === "stripeId" ? sortOrder : "asc"}
                  onClick={() => handleSort("stripeId")}
                >
                  Stripe ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === "price"}
                  direction={sortColumn === "price" ? sortOrder : "asc"}
                  onClick={() => handleSort("price")}
                >
                  Price
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === "purchaseStatus"}
                  direction={
                    sortColumn === "purchaseStatus" ? sortOrder : "asc"
                  }
                  onClick={() => handleSort("purchaseStatus")}
                >
                  Purchase Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === "applicationFee"}
                  direction={
                    sortColumn === "applicationFee" ? sortOrder : "asc"
                  }
                  onClick={() => handleSort("applicationFee")}
                >
                  Application Fee
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === "lastUpdated"}
                  direction={sortColumn === "lastUpdated" ? sortOrder : "asc"}
                  onClick={() => handleSort("lastUpdated")}
                >
                  Last Updated
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.map((purchase, index) => (
              <TableRow key={index}>
                <TableCell>{purchase.buyerGitHubUsername}</TableCell>
                <TableCell>{purchase.repo}</TableCell>
                <TableCell>{purchase.stripeId}</TableCell>
                <TableCell>${(purchase.price / 100).toFixed(2)}</TableCell>
                <TableCell>{purchase.purchaseStatus}</TableCell>
                <TableCell>
                  ${(purchase.applicationFee / 100).toFixed(2)}
                </TableCell>
                <TableCell>
                  {formatUnixTimestamp(purchase.lastUpdated)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TableFooter>
        <Pagination
          count={Math.ceil(filteredPurchases.length / rowsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          sx={{ display: "flex", justifyContent: "center", margin: 2 }}
        />
      </TableFooter>
    </CardPanel>
  );
};
