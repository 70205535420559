export const toTitleCase = (str: string) => {
  return str
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const formatUnixTimestamp = (unixTimestamp: number): string => {
  const date = new Date(unixTimestamp * 1000); // Convert seconds to milliseconds
  return date.toLocaleString(); // Format the date as a human-readable string
};

export const centsToDollars = (cents: number) => {
  return cents / 100;
};
