import { Box, Link, Typography } from "@mui/material";
import appConfig from "appConfig";
import React from "react";

interface ProfileUrlProps {
  username: string;
}

const ProfileUrl: React.FC<ProfileUrlProps> = ({ username }) => {
  const profileUrl = `${appConfig.ui_domain}/profile/${username}`;

  return (
    <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
      <Typography variant="body1" sx={{ marginRight: 1 }}>
        Profile URL:
      </Typography>
      <Box
        sx={{
          backgroundColor: "#f5f5f5", // Light gray background
          padding: "4px 8px", // Padding for the clickable area
          borderRadius: "4px", // Rounded corners
          fontFamily: "monospace", // Monospace font for code-like appearance
          border: "1px solid #e0e0e0", // Border styling
          display: "inline-block",
          cursor: "pointer", // Pointer to indicate it's clickable
          "&:hover": { backgroundColor: "#e0e0e0" }, // Hover effect
          transition: "background-color 0.3s ease", // Smooth hover transition
        }}
      >
        <Link
          href={profileUrl}
          target="_blank"
          sx={{ color: "#1976d2", textDecoration: "none", fontSize: "inherit" }}
        >
          {profileUrl}
        </Link>
      </Box>
    </Box>
  );
};

export default ProfileUrl;
