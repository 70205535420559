import { Box, Skeleton, SxProps, Theme } from "@mui/material";
import React from "react";

interface ProfileImageProps {
  src?: string; // src is now optional
  sx?: SxProps<Theme>;
}

const ProfileImage: React.FC<ProfileImageProps> = ({ src, sx }) => {
  return (
    <>
      {src ? (
        <Box
          component="img"
          src={src}
          alt="Profile Image"
          sx={{
            width: 40,
            height: "auto",
            borderRadius: "50%",
            objectFit: "cover",
            ...sx,
          }}
        />
      ) : (
        <Skeleton
          variant="circular"
          width={250}
          height={250}
          sx={{
            ...sx,
          }}
        />
      )}
    </>
  );
};

export default ProfileImage;
