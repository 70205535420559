import PaymentsIcon from "@mui/icons-material/Payments";
import { Box, Button } from "@mui/material";
import { AlertBox, AlertNotificationProps } from "Components/Common/AlertBox";
import React, { useState } from "react";
import { fetchWithJwt } from "utils/apiRequest";

const ViewPaymentDashboardButton = () => {
  const [alertMessage, setAlertMessage] = useState<AlertNotificationProps>();
  const openPaymentDashboard = async () => {
    // Open the window immediately to avoid Safari popup blocker
    const newWindow = window.open("", "_blank");

    const response = await fetchWithJwt({
      endpoint: "/payments/dashboardLink",
      method: "GET",
      customResponseHandling: true,
    });

    if (response.status === 404) {
      const errorData = await response.json();
      setAlertMessage({
        message: errorData.message,
        severity: "error",
      });
    } else {
      const data = await response.json();

      if (data?.dashboard?.url) {
        newWindow.location.href = data?.dashboard?.url;
      } else {
        setAlertMessage({
          message: "Problem getting Stripe dashboard link.",
          severity: "error",
        });
      }
    }
  };
  return (
    <>
      <AlertBox alert={alertMessage} />
      <Box sx={{ display: "flex", justifyContent: "left" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PaymentsIcon />}
          sx={{ textTransform: "none", marginBottom: "1%" }}
          onClick={openPaymentDashboard}
        >
          View Payment Dashboard
        </Button>
      </Box>
    </>
  );
};

export default ViewPaymentDashboardButton;
