import {
  Box,
  Button,
  CardActions,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {
  AlertSnackbar,
  AlertSnackbarNotificationProps,
} from "Components/Common/AlertSnackbar";
import { fetchListingByRepo } from "Components/Listings/ListingApiRequests";
import { ListingDTO } from "dtos/ListingDTO";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardPanel from "../../Common/CardPanel";
import BuyNowOrderDraw from "./BuyNow/BuyNowOrderDraw";
import DetailedRepoListing from "./DetailedRepoListing";

const Repo = ({ username, repo }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] =
    useState<AlertSnackbarNotificationProps>({
      open: false,
    });
  const [listing, setListing] = useState<ListingDTO>();
  const [buyNowOrderDrawerOpen, setBuyNowOrderDrawerOpen] = useState(false);

  useEffect(() => {
    fetchListingByRepo(setListing, setNotification, setLoading, username, repo);
  }, [username, repo]);

  const isProfileEmpty = listing && Object.keys(listing).length === 0;

  const handleViewProfile = () => navigate(`/profile/${username}`);

  return (
    <Box
      sx={{
        width: "80%",
        margin: "0 auto",
        marginTop: "10px",
        display: "flex",
        minHeight: "100vh",
      }}
    >
      <AlertSnackbar
        notification={notification}
        setNotification={setNotification}
      />
      <BuyNowOrderDraw
        drawerOpen={buyNowOrderDrawerOpen}
        setDrawerOpen={setBuyNowOrderDrawerOpen}
        listing={listing}
      />
      <CardPanel title="" sx={{ flexGrow: 1, padding: "25px" }}>
        <Stack spacing={3}>
          {loading ? (
            // Display skeletons while loading
            <>
              <Skeleton variant="rectangular" height={50} />
              <Skeleton variant="rectangular" height={50} />
              <Skeleton variant="rectangular" height={50} width="50%" />
              <Skeleton variant="rectangular" height={40} width="30%" />
              <CardActions sx={{ justifyContent: "space-between", height: 70 }}>
                <Skeleton variant="rectangular" width="45%" height={40} />
                <Skeleton variant="rectangular" width="45%" height={40} />
              </CardActions>
            </>
          ) : !isProfileEmpty ? (
            <>
              <DetailedRepoListing listing={listing} />
              <CardActions sx={{ justifyContent: "space-between", height: 70 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ height: "100%", flex: 1 }}
                  onClick={() => setBuyNowOrderDrawerOpen(true)}
                >
                  Buy Now
                </Button>
                <Button
                  variant="outlined"
                  sx={{ height: "100%", flex: 1 }}
                  onClick={handleViewProfile}
                >
                  View Full profile
                </Button>
              </CardActions>
            </>
          ) : (
            <>
              <Typography sx={{ textAlign: "center" }}>
                Listing {repo} for {username} was not found.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ height: "100%", flex: 1 }}
                onClick={handleViewProfile}
              >
                Visit Profile
              </Button>
            </>
          )}
        </Stack>
      </CardPanel>
    </Box>
  );
};

export default Repo;
