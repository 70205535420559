import { Box } from "@mui/material";
import React, { forwardRef } from "react";

interface BackgroundBoxProps {
  children: React.ReactNode; // Accept children for content
  sx?: React.CSSProperties; // Optional custom styles
  id?: string; // HTML id for element
}

const LightSection = forwardRef<HTMLDivElement, BackgroundBoxProps>(
  ({ children, sx, id }, ref) => {
    return (
      <Box
        id={id}
        ref={ref} // Attach the forwarded ref here
        className="banner"
        sx={{
          color: "#1b0033",
          backgroundColor: "#f6f8fb",
          textAlign: "center",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          height: "auto",
          opacity: 1,
          transform: "translateY(0)",
          transition:
            "opacity 700ms cubic-bezier(0.4, 0, 0.2, 1), transform 150ms ease-in-out",
          ...sx, // Allow additional styles to be applied
        }}
      >
        {children}
      </Box>
    );
  }
);

export default LightSection;
