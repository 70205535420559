import { Alert, Snackbar } from "@mui/material";
import React from "react";

export interface AlertSnackbarNotificationProps {
  open: boolean;
  message?: string;
  severity?: "success" | "info" | "warning" | "error";
}

export interface AlertSnackbarProps {
  notification: AlertSnackbarNotificationProps;
  setNotification: React.Dispatch<
    React.SetStateAction<AlertSnackbarNotificationProps>
  >;
}

export const AlertSnackbar = (props: AlertSnackbarProps) => {
  const { notification, setNotification } = props;

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={6000}
      onClose={() => setNotification({ ...notification, open: false })}
    >
      <Alert
        severity={notification.severity || "info"} // Default to "info" if severity is not provided
        sx={{ mb: "2%" }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};
