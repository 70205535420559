import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  AlertSnackbar,
  AlertSnackbarNotificationProps,
} from "Components/Common/AlertSnackbar";
import BuyNowOrderDraw from "Components/Profile/PublicPage/BuyNow/BuyNowOrderDraw";
import DetailedRepoListing from "Components/Profile/PublicPage/DetailedRepoListing";
import { ListingDTO } from "dtos/ListingDTO";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserName } from "utils/authentication";
import { fetchListingData } from "./ListingApiRequests";
interface ListingGridProps {
  username?: string;
}

const ListingsGrid = ({ username = getUserName() }: ListingGridProps) => {
  const navigate = useNavigate();
  const [buyNowOrderDrawerOpen, setBuyNowOrderDrawerOpen] = useState(false);
  const [listings, setListings] = useState<ListingDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState<ListingDTO>();

  const [notification, setNotification] =
    useState<AlertSnackbarNotificationProps>({
      open: false,
    });
  const [page, setPage] = useState(1);
  const itemsPerPage = 4;

  useEffect(() => {
    fetchListingData(setListings, setNotification, setLoading, username);
  }, [username]);

  // Handle opening the modal
  const handleMoreInfoClick = (listing: ListingDTO) => {
    setSelectedListing(listing);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Calculate the listings for the current page
  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const paginatedListings = listings.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handleBuy = async (listing: ListingDTO) => {
    setSelectedListing(listing);
    setBuyNowOrderDrawerOpen(true);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AlertSnackbar
        notification={notification}
        setNotification={setNotification}
      />
      <BuyNowOrderDraw
        drawerOpen={buyNowOrderDrawerOpen}
        setDrawerOpen={setBuyNowOrderDrawerOpen}
        listing={selectedListing}
      />
      <Grid container spacing={2}>
        {loading ? (
          Array.from(new Array(itemsPerPage)).map((_, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card
                sx={{
                  height: 250,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <CardContent>
                  <Skeleton variant="text" height={64} />
                  <Skeleton variant="text" height={24} width="60%" />
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Skeleton variant="rectangular" height={40} width="100%" />
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : paginatedListings.length > 0 ? (
          paginatedListings.map((listing) => (
            <Grid item xs={12} sm={6} key={listing.id || listing.repo}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <CardContent>
                  <IconButton
                    color="primary"
                    sx={{ float: "right" }}
                    onClick={() => {
                      navigate(`${window.location.pathname}/${listing.repo}`);
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                  <Typography variant="h6" component="div">
                    {listing.repo}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Price: ${listing.price}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{ justifyContent: "space-between", height: 70 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: "100%", flex: 1 }}
                    onClick={() => handleBuy(listing)}
                  >
                    Buy
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ height: "100%", flex: 1, marginLeft: 1 }}
                    onClick={() => handleMoreInfoClick(listing)}
                  >
                    More Info
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary" align="center">
              No repositories available
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* Pagination */}
      <Box display="flex" justifyContent="center" marginTop={2}>
        {loading ? (
          <Skeleton variant="rectangular" width={200} height={40} />
        ) : (
          paginatedListings.length > 0 && (
            <Pagination
              count={Math.ceil(listings.length / itemsPerPage)}
              page={page}
              onChange={handlePaginationChange}
              color="primary"
            />
          )
        )}
      </Box>

      {/* Modal for More Info */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        sx={{ "& .MuiDialog-paper": { width: "90%", height: "50%" } }}
      >
        <DialogContent>
          <DetailedRepoListing listing={selectedListing} />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ListingsGrid;
