import { Box, Typography } from "@mui/material";
import ListingsGrid from "Components/Listings/ListingGrid";
import { ProfileDTO } from "dtos/ProfileDTO";
import React, { useEffect, useState } from "react";
import CardPanel from "../../Common/CardPanel";
import AboutMe from "../AboutMe";
import { fetchUserPublicProfileData } from "../profileApiRequest";
import ProfileImage from "../ProfileImage";
import ProfileName from "../ProfileName";

const PublicProfile = ({ username }) => {
  const [publicProfile, setPublicProfile] = useState<ProfileDTO>();

  useEffect(() => {
    fetchUserPublicProfileData(setPublicProfile, username);
  }, [username]); // Ensure username change triggers profile fetch

  const isProfileEmpty =
    publicProfile && Object.keys(publicProfile).length === 0;

  return (
    <Box
      sx={{
        width: "80%",
        margin: "0 auto", // Center the card horizontally
        display: "flex", // Flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        minHeight: "100vh", // Take up the full viewport height
      }}
    >
      <CardPanel
        title=""
        sx={{ flexGrow: 1, textAlign: "center", paddingBottom: "15px" }}
      >
        {!isProfileEmpty ? (
          <>
            <ProfileImage
              src={publicProfile?.profilePictureUrl}
              sx={{ width: 250 }}
            />
            <ProfileName name={publicProfile?.name} variant="h4" />
            <AboutMe text={publicProfile?.about} />
            <ListingsGrid username={username} />
          </>
        ) : (
          <Typography>{username} not found</Typography>
        )}
      </CardPanel>
    </Box>
  );
};

export default PublicProfile;
