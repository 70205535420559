import { Grid } from "@mui/material";
import PageTemplate from "Components/Common/PageTemplate";
import ViewPaymentDashboardButton from "Components/Dashboard/ViewPaymentDashboardButton";
import { PurchasesTable } from "Components/Dashboard/Widgets/PurchasesTable";
import { TotalNumberOfSalesWidget } from "Components/Dashboard/Widgets/TotalNumberOfSalesWidget";
import { TotalRevenueWidget } from "Components/Dashboard/Widgets/TotalRevenueWidget";
import React from "react";

export default function HomePage() {
  return (
    <PageTemplate>
      <ViewPaymentDashboardButton />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TotalRevenueWidget />
        </Grid>
        <Grid item xs={12} md={6}>
          <TotalNumberOfSalesWidget />
        </Grid>
      </Grid>
      <PurchasesTable />
    </PageTemplate>
  );
}
