import Seo from "Components/Common/Seo";
import Banner from "Components/HomePage/Banner";
import FAQSection from "Components/HomePage/FAQSection";
import Features from "Components/HomePage/Features";
import FloatingNavBar from "Components/HomePage/FloatingNavBar";
import Footer from "Components/HomePage/Footer";
import HowToGuides from "Components/HomePage/HowToGuides";
import PricingSection from "Components/HomePage/PricingSection";
import React from "react";

export default function HomePage() {
  return (
    <>
      <Seo description="Monetize your code by selling access to your GitHub repositories. Join us for secure transactions, easy management, and analytics to track your success." />
      <FloatingNavBar />
      <Banner />
      <Features />
      <PricingSection />
      <FAQSection />
      <HowToGuides />
      <Footer />
    </>
  );
}
