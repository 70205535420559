import CardPanel from "Components/Common/CardPanel";
import PageTemplate from "Components/Common/PageTemplate";
import ToggleButtonGroup from "Components/Common/ToggleButtonGroup";
import ManageListings from "Components/Listings/ManageListings";
import SellStepper from "Components/Listings/SellStepper";
import React, { useState } from "react";

export default function ListingsPage() {
  const buttonLabels = ["Manage", "Add"];
  const [selection, setSelection] = useState<string>(buttonLabels[0]);

  return (
    <PageTemplate>
      <ToggleButtonGroup
        labels={buttonLabels}
        selected={selection}
        onSelect={setSelection}
      />

      {selection === buttonLabels[0] && (
        <CardPanel title="Manage Listings">
          <ManageListings />
        </CardPanel>
      )}
      {selection === buttonLabels[1] && (
        <CardPanel title="Add a New Listing">
          <SellStepper />
        </CardPanel>
      )}
    </PageTemplate>
  );
}
