// TopBar.tsx
import { Box } from "@mui/material";
import ProfileWithMenu from "Components/Profile/ProfileWithMenu";
import React from "react";

const TopBar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: 2,
      }}
    >
      <ProfileWithMenu />
    </Box>
  );
};

export default TopBar;
