import { Box } from "@mui/material";
import React from "react";
const Logo: React.FC = () => {
  return (
    <Box />
    // <Box
    //   display="flex"
    //   alignItems="center"
    //   sx={{
    //     gap: 1, // Spacing between icon and text
    //   }}
    // >
    //   <PinIcon />
    //   <Typography
    //     variant="h6"
    //     sx={{
    //       fontWeight: "bold",
    //     }}
    //   >
    //     My Project
    //   </Typography>
    // </Box>
  );
};

export default Logo;
