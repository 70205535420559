import { Grid, Pagination, Paper, Skeleton, Typography } from "@mui/material";
import PostCard from "Components/Sanity/PostCard";
import client from "Components/Sanity/sanityClient";
import { PostType } from "Components/Sanity/types";
import React, { useEffect, useState } from "react";

const POSTS_PER_PAGE = 3; // Number of posts per page

export const HowToGuidesCards = () => {
  const [postData, setPost] = useState<PostType[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post" && "f5f06d4b-a631-45ec-bd70-e85a0106b2a0" in categories[]._ref]{
          title,
          description,
          slug,
          _id,
          mainImage {
            asset-> {
              _id,
              url
            },
            alt
          },
          body
        }`
      )
      .then((data: PostType[]) => {
        const sortedPosts = data
          .filter((post) => post._id)
          .sort((a, b) => b._id.localeCompare(a._id));
        setPost(sortedPosts);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.error(err);
        setError("Failed to fetch posts.");
        setLoading(false);
      });
  }, []);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const totalPages = Math.ceil((postData?.length ?? 0) / POSTS_PER_PAGE);
  const paginatedPosts = postData?.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  );

  if (error) return <p>{error}</p>;

  return (
    <>
      {loading ? (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {Array.from(new Array(POSTS_PER_PAGE)).map((_, index) => (
            <Grid item xs={12} lg={4} key={index}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Skeleton variant="rectangular" width="100%" height={140} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="text" width="60%" height={20} />
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : paginatedPosts && paginatedPosts.length > 0 ? (
        <>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {paginatedPosts.map((post) => (
              <Grid item xs={12} lg={4} key={post?.slug?.current}>
                <PostCard post={post} />
              </Grid>
            ))}
          </Grid>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              marginTop: 2,
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#f6f8fb",
              borderRadius: "8px",
              padding: "5px",
              "& .MuiPaginationItem-root": {
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
                "&.Mui-selected": {
                  backgroundColor: "#3f51b5",
                  color: "white",
                },
              },
            }}
          />
        </>
      ) : (
        <Typography variant="body1">Coming Soon...</Typography>
      )}
    </>
  );
};

export default HowToGuidesCards;
