import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { ListingDTO } from "dtos/ListingDTO";
import React, { useState } from "react";
import { steps } from "./config";
import Finished from "./FinishedStep";
import LoginWithGitHub from "./GitHubUsernameStep";
import PaymentStep from "./PaymentStep";

interface BuyNowStepperProps {
  onClose: () => void;
  listing: ListingDTO;
}

const BuyNowStepper: React.FC<BuyNowStepperProps> = ({ onClose, listing }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [gitHubUsername, setGitHubUsername] = useState("");
  const [intentId, setIntentId] = useState<string>("");

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleClose = () => {
    setActiveStep(0);
    setGitHubUsername("");
    onClose();
  };
  return (
    <Box sx={{ width: "100%" }}>
      {/* Only render the stepper if the user hasn't finished all steps */}
      {activeStep < steps.length && (
        <Stepper activeStep={activeStep} sx={{ marginBottom: 4 }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {/* Conditionally render the content for each step */}
      {activeStep === 0 ? (
        <LoginWithGitHub
          handleNext={handleNext}
          gitHubUsernameState={{ setGitHubUsername, gitHubUsername }}
        />
      ) : activeStep === 1 ? (
        <PaymentStep
          githubUserName={gitHubUsername}
          navigationButtons={{ handleNext, handleBack }}
          listing={listing}
          intentIdState={{ setIntentId, intentId }}
        />
      ) : (
        // ) : activeStep === 2 ? (
        //   <h1>abc</h1>
        <Finished handleClose={handleClose} intentId={intentId} />
      )}
    </Box>
  );
};

export default BuyNowStepper;
