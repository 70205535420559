import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { AlertBox, AlertNotificationProps } from "Components/Common/AlertBox";
import React, { useEffect, useState } from "react";
import {
  fetchAccountPayoutStatus,
  getStripeConnectConfigureLink,
} from "./payoutsApi";

const ConfigureStripeConnectButton = () => {
  const [alertMessage, setAlertMessage] = useState<AlertNotificationProps>();
  const [stripeConnectionStatus, setStripeConnectionStatus] = useState<
    boolean | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchAccountPayoutStatus(setStripeConnectionStatus, setAlertMessage);
  }, []);

  const redirectToStripe = async () => {
    // Open the window immediately to avoid Safari popup blocker
    const newWindow = window.open("", "_blank");

    setLoading(true);
    const [status, response] = await getStripeConnectConfigureLink();
    setLoading(false);

    if (status && response) {
      // Redirect the new window to the Stripe URL
      newWindow.location.href = response;
    } else {
      // Close the window and show an error if the URL is not valid
      newWindow.close();
      setAlertMessage({
        message: `Problem getting Stripe configuration link. ${response}`,
        severity: "error",
      });
    }
  };

  return (
    <Box sx={{ display: "grid", alignItems: "center" }}>
      <Typography variant="body1" sx={{ marginRight: 2 }}>
        Payout Details:
      </Typography>
      <AlertBox alert={alertMessage} />
      {stripeConnectionStatus ? (
        <Button
          disableFocusRipple
          disableRipple
          variant="contained"
          sx={{
            backgroundColor: "green",
            color: "white",
            marginBottom: "10px",
          }}
        >
          Successfully linked with Stripe
        </Button>
      ) : (
        <Button
          onClick={redirectToStripe}
          variant="contained"
          color="primary"
          sx={{ marginBottom: "10px", position: "relative" }}
          disabled={stripeConnectionStatus === null || loading}
        >
          {stripeConnectionStatus === null || loading ? (
            <CircularProgress size={24} />
          ) : (
            "Configure Payouts with Stripe Connect"
          )}
        </Button>
      )}
      <Typography variant="caption" sx={{ marginRight: 2 }}>
        Stripe Connect is a service that allows you to integrate with Stripe to
        handle payments and payouts. As a seller, you can use it to receive
        payouts directly to your bank account, manage your payments, and handle
        various aspects of financial transactions.
      </Typography>
    </Box>
  );
};

export const ConfigurePayout = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <ConfigureStripeConnectButton />
    </Box>
  );
};

export default ConfigurePayout;
