import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const MonitizeWordRotator: React.FC = () => {
  // List of words to rotate through
  const words = [
    "GitHub Repo",
    "Splunk App",
    "WordPress Theme",
    "Scripts",
    "Discord Bot",
  ];

  // State to manage the current index and visibility of the word
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false); // Fade out the current word
      // After fading out, change the word
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsVisible(true); // Fade in the next word
      }, 500); // Wait for the fade-out animation to complete
    }, 1500); // Change word every 1.5 seconds

    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <Typography
      color="secondary"
      variant="inherit"
      sx={{
        // textDecoration: "underline",
        display: "inline",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
      }}
    >
      {words[currentIndex]}.
    </Typography>
  );
};

export default MonitizeWordRotator;
