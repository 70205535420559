import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { AlertSnackbarNotificationProps } from "Components/Common/AlertSnackbar";
import { ListingDTO } from "dtos/ListingDTO";
import React from "react";
import { updateListing } from "./ListingApiRequests";

interface EditListingProps {
  open: boolean;
  onClose: () => void;
  record: ListingDTO | null;
  onSave: (id: string, updatedRecord: ListingDTO) => void;
  setNotification: React.Dispatch<
    React.SetStateAction<AlertSnackbarNotificationProps>
  >;
}

const EditListing: React.FC<EditListingProps> = ({
  open,
  onClose,
  record,
  onSave,
  setNotification,
}) => {
  const [formData, setFormData] = React.useState<{
    description: string;
    price: number;
  } | null>(null);

  React.useEffect(() => {
    if (record) {
      setFormData({
        description: record.description,
        price: record.price,
      });
    }
  }, [record]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: id === "price" ? parseFloat(value) : value, // Handle price as a number
    }));
  };

  const handleSave = () => {
    if (record && formData) {
      // Create the updated record including the non-editable fields
      const updatedRecord: ListingDTO = {
        ...record,
        description: formData.description,
        price: formData.price,
      };
      onSave(record.id, updatedRecord); // Call parent onSave if needed
      updateListing(setNotification, updatedRecord); // Call API to update the listing
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Listing</DialogTitle>
      <DialogContent>
        <TextField
          id="description"
          label="Description"
          value={formData?.description || ""}
          onChange={handleChange}
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          id="price"
          label="Price ($)"
          type="number"
          value={formData?.price || ""}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditListing;
