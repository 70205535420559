import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import appConfig from "appConfig";
import React, { useState } from "react";
import PaymentComplete from "./PaymentComplete";

interface CheckoutFormProps {
  paymentSuccessful: boolean;
  setPaymentSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
  onPaymentSuccess: () => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  paymentSuccessful,
  setPaymentSuccessful,
  onPaymentSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${appConfig.ui_domain}/complete`,
      },
      redirect: "if_required",
    });

    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message || "An error occurred.");
    } else if (paymentIntent?.status === "succeeded") {
      setPaymentSuccessful(true);
      onPaymentSuccess();
    } else {
      setMessage("An unexpected error occurred." + error.type);
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  return (
    <Box
      component="form"
      id="payment-form"
      onSubmit={handleSubmit}
      sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}
    >
      {/* Display any error or success messages */}
      {message && (
        <Typography
          variant="body2"
          color="error"
          id="payment-message"
          sx={{ mt: 2 }}
        >
          {message}
        </Typography>
      )}

      {!paymentSuccessful ? (
        <>
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading || !stripe || !elements}
            sx={{ mt: 2 }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Pay now"
            )}
          </Button>
        </>
      ) : (
        <PaymentComplete />
      )}
    </Box>
  );
};

export default CheckoutForm;
