import { useEffect } from "react";
import { logout } from "utils/authentication";

const LogoutPage = () => {
  useEffect(() => {
    logout();
  }, []);

  return null;
};

export default LogoutPage;
