import appConfig from "appConfig";
import { getJwt } from "./authentication";

interface FetchOptions extends RequestInit {
  endpoint: string;
  customResponseHandling?: boolean;
}

/**
 * Helper function to make fetch calls with JWT authorization.
 *
 * @param {FetchOptions} options - Options including the URL and other fetch options.
 * @returns {Promise<Response>} - The fetch promise.
 */
export const fetchWithJwt = async (
  options: FetchOptions
): Promise<Response> => {
  const { endpoint, customResponseHandling, ...fetchOptions } = options;

  // Set default headers or merge with existing ones
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    ...fetchOptions.headers,
  };

  // Add Authorization header
  headers["Authorization"] = `Bearer ${getJwt()}`;

  // Make the fetch call
  try {
    const response = await fetch(`${appConfig.api_base_url}${endpoint}`, {
      ...fetchOptions,
      headers,
    });

    if (customResponseHandling) {
      return response;
    }

    if (!response.ok) {
      // Handle non-200 responses
      const error = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, ${error}`);
    }

    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const fetchUnauthenticated = async (
  options: FetchOptions
): Promise<Response> => {
  const { endpoint, ...fetchOptions } = options;

  // Set default headers or merge with existing ones
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    ...fetchOptions.headers,
  };

  // Make the fetch call
  try {
    const response = await fetch(`${appConfig.api_base_url}${endpoint}`, {
      ...fetchOptions,
      headers,
    });

    // Handle non-200 responses
    if (!response.ok) {
      const error = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, ${error}`);
    }

    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
