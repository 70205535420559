import {
  Box,
  Card,
  CardContent,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "13px", // Adjust as needed
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Adjust shadow as needed
  border: `1px solid ${theme.palette.secondary.light}`, // Set border color to secondary.light
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)", // Shadow on hover
  },
  marginBottom: "15px",
}));

interface CardPanelProps {
  title?: any;
  description?: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  compact?: boolean;
}

const CardPanel: React.FC<CardPanelProps> = ({
  title,
  description,
  children,
  sx,
  compact,
}) => {
  return (
    <StyledCard sx={{ ...sx }}>
      <CardContent>
        {title && (
          <Typography variant="h5" component="div">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        )}
        <Box sx={{ marginTop: compact ? 0 : "25px" }}>{children}</Box>
      </CardContent>
    </StyledCard>
  );
};

export default CardPanel;
