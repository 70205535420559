import { Box, FormControl, FormGroup, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

type Config = {
  price: number;
  description: string;
};

// Define the interface for the configuration settings
interface ConfigureListingProps {
  onSave: (config: Config) => void;
}

const ConfigureListing: React.FC<ConfigureListingProps> = ({ onSave }) => {
  const [price, setPrice] = useState<string | "">("");
  const [description, setDescription] = useState<string>("");

  // Call onSave when any value changes and all fields are filled
  useEffect(() => {
    if (price !== "" && description) {
      onSave({
        price: Number(price),
        description,
      });
    }
  }, [price, description, onSave]);

  // Handle price change
  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || !isNaN(Number(value))) {
      setPrice(value);
    }
  };

  return (
    <Box>
      <FormGroup>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Price ($)"
            type="text"
            value={price}
            onChange={handlePriceChange}
            variant="outlined"
            fullWidth
            required
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            required
          />
        </FormControl>
      </FormGroup>
    </Box>
  );
};

export default ConfigureListing;
