import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { checkGitHubUsername } from "Components/GitHubAuthentication/helpers";
import React, { useEffect, useState } from "react";
import NavigationButtons from "./NavigationButtons";

export interface GitHubUsernameStateProps {
  gitHubUsername: string;
  setGitHubUsername: React.Dispatch<React.SetStateAction<string>>;
}

interface LoginWithGitHubProps {
  gitHubUsernameState: GitHubUsernameStateProps;
  handleNext: () => void;
}

const GitHubUsernameStep: React.FC<LoginWithGitHubProps> = ({
  handleNext,
  gitHubUsernameState,
}) => {
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (gitHubUsernameState.gitHubUsername) {
      setLoading(true);
      checkGitHubUsername(gitHubUsernameState.gitHubUsername)
        .then((valid) => {
          setIsValid(valid);
          setLoading(false);
        })
        .catch(() => {
          setIsValid(false);
          setLoading(false);
        });
    } else {
      setIsValid(null); // No username entered
    }
  }, [gitHubUsernameState.gitHubUsername]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: 400,
        margin: "0 auto",
      }}
    >
      <Typography variant="h6">Step 1: GitHub Details</Typography>
      <TextField
        label="GitHub Username"
        variant="outlined"
        fullWidth
        margin="normal"
        value={gitHubUsernameState.gitHubUsername}
        onChange={(e) => gitHubUsernameState.setGitHubUsername(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading ? (
                <CircularProgress sx={{ color: "grey" }} size={24} />
              ) : isValid !== null ? (
                isValid ? (
                  <CheckCircleIcon sx={{ color: "green" }} />
                ) : (
                  <CancelIcon sx={{ color: "red" }} />
                )
              ) : null}
            </InputAdornment>
          ),
        }}
      />
      <NavigationButtons
        handleNext={handleNext}
        isNextDisabled={
          gitHubUsernameState.gitHubUsername.trim() === "" || !isValid
        }
      />
    </Box>
  );
};

export default GitHubUsernameStep;
