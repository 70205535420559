import { Alert } from "@mui/material";
import React from "react";

export interface AlertNotificationProps {
  message?: string;
  severity?: "success" | "info" | "warning" | "error";
}

export const AlertBox: React.FC<{ alert: AlertNotificationProps }> = ({
  alert,
}) => {
  if (alert?.message) {
    return (
      <Alert
        severity={alert.severity || "info"} // Default to "info" if severity is not provided
        sx={{ mb: "2%" }}
      >
        {alert.message}
      </Alert>
    );
  }
  return <></>;
};
