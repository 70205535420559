import { Box } from "@mui/material";
import React, { forwardRef } from "react";

interface BackgroundBoxProps {
  children: React.ReactNode; // Accept children for content
  sx?: React.CSSProperties; // Optional custom styles
  id?: string; // HTML id for element
}

const DarkSection = forwardRef<HTMLDivElement, BackgroundBoxProps>(
  ({ children, sx, id }, ref) => {
    return (
      <Box
        id={id}
        ref={ref} // Forward the ref to allow intersection observation
        className="banner"
        sx={{
          background: "linear-gradient(135deg, rgb(13, 13, 21), #1b0033 70%)",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          height: "auto",
          opacity: 1,
          transform: "translateY(0)",
          transition:
            "opacity 700ms cubic-bezier(0.4, 0, 0.2, 1), transform 150ms ease-in-out",
          ...sx, // Allow additional styles to be applied
        }}
      >
        {children}
      </Box>
    );
  }
);

export default DarkSection;
