import { CircularProgress } from "@mui/material";
import { TotalRevenue } from "dtos/apiResponses/PurchasesDTO";
import React, { useEffect, useState } from "react";
import { fetchWithJwt } from "utils/apiRequest";
import { centsToDollars } from "utils/stringUtils";
import { SingleVlaueWidget } from "./Components/SingleValue";

export const TotalRevenueWidget = () => {
  const [totalRevenue, setTotalRevenue] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPurchases = async () => {
      try {
        const response = await fetchWithJwt({
          endpoint: "/purchases/totalRevenue",
          method: "GET",
        });
        const data: TotalRevenue = await response.json();
        setTotalRevenue(centsToDollars(data.totalRevenue));
      } catch (err) {
        setError("Failed to load total revenue.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchases();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;

  return (
    <SingleVlaueWidget
      title="Total Revenue"
      description="total revenue, excluding application/transaction fees."
      value={`$${totalRevenue.toLocaleString()}`}
    />
  );
};
