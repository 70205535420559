import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const StyledNavButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "transparent",
  padding: "10px 15px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "8px",
  },
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  padding: "10px 15px",
  borderRadius: "20px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  width: "auto",
}));

const menuItems = [
  { label: "Home", path: "/", id: "banner" },
  { label: "Features", path: "/", id: "features" },
  { label: "Price", path: "/", id: "price" },
  { label: "FAQs", path: "/", id: "faqs" },
  { label: "Guides", path: "/", id: "HowToGuides" },
];

const actionItems = [
  { label: "Get Started", path: "/login" },
  { label: "Login", path: "/login" },
];

const FloatingNavBar: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateAndScroll = (path: string, sectionId: string) => {
    // Navigate to the path
    navigate(path);
    // Scroll to the section after a short delay to allow the page to load
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 300); 
  };

  const renderMobileMenuItems = () => (
    <>
      {menuItems.map(({ label, path, id }) => (
        <MenuItem
          key={label}
          onClick={() => handleNavigateAndScroll(path, id)}
        >
          {label}
        </MenuItem>
      ))}
      {actionItems.map(({ label, path }) => (
        <MenuItem key={label} onClick={handleMenuClose}>
          <ActionButton
            sx={{ width: "100%" }}
            onClick={() => navigate(path)} // Use the path for navigation
          >
            {label}
          </ActionButton>
        </MenuItem>
      ))}
    </>
  );

  return (
    <Box
      sx={{
        position: "fixed",
        top: "20px",
        left: "50%",
        transform: "translateX(-50%)",
        width: isMobile ? "300px" : "600px",
        display: "flex",
        justifyContent: isMobile ? "space-between" : "space-around",
        alignItems: "center",
        zIndex: 1000,
        borderRadius: "20px",
        backgroundColor: "rgba(24, 3, 44, 0.8)",
        padding: isMobile ? "10px" : "0",
      }}
    >
      {isMobile ? (
        <>
          <IconButton onClick={handleMenuClick} sx={{ color: "white" }}>
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {renderMobileMenuItems()}
          </Menu>
        </>
      ) : (
        <>
          {menuItems.map(({ label, path, id }) => (
            <StyledNavButton
              key={label}
              onClick={() => handleNavigateAndScroll(path, id)} 
              variant="text"
            >
              {label}
            </StyledNavButton>
          ))}
          {actionItems.map(({ label, path }) => (
            <ActionButton
              key={label}
              onClick={() => navigate(path)} // Use the path for navigation
            >
              {label}
            </ActionButton>
          ))}
        </>
      )}
    </Box>
  );
};

export default FloatingNavBar;
