import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DarkSection from "./DarkSection"; // Import DarkSection component

const PricingSection: React.FC = () => {
  const navigate = useNavigate();

  // State to control the animation
  const [animate, setAnimate] = useState(false);
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setAnimate(true);
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    // Copy the current ref value to a local variable
    const currentSectionRef = sectionRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  return (
    <DarkSection id="price" ref={sectionRef}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // Stack heading and card vertically
          alignItems: "center", // Center both heading and card
          textAlign: "center", // Center text inside the section
          padding: "40px 0", // Add padding to the section
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            color: "white",
            fontSize: { xs: "40px", sm: "50px" },
            marginBottom: "20px", // Space between heading and card
            opacity: animate ? 1 : 0,
            transform: animate ? "translateY(0)" : "translateY(-30px)",
            transition:
              "opacity 700ms ease-in-out, transform 700ms ease-in-out",
          }}
        >
          Free, forever.
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: "white",
            marginBottom: "20px",
            opacity: animate ? 1 : 0,
            transform: animate ? "translateY(0)" : "translateY(-20px)",
            transition:
              "opacity 700ms ease-in-out, transform 700ms ease-in-out",
            transitionDelay: "200ms", // Slight delay for staggered effect
          }}
        >
          Just pay transaction fees
        </Typography>

        <Card
          sx={{
            maxWidth: 400,
            width: "100%", // Ensure responsiveness
            borderRadius: "16px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            padding: "20px",
            opacity: animate ? 1 : 0,
            transform: animate
              ? "scale(1) rotate(0deg)"
              : "scale(0.8) rotate(-5deg)", // Scale and rotate effect
            transition: "opacity 800ms ease, transform 800ms ease", // Smooth transition
            transitionDelay: "400ms", // Delay to synchronize with the rest
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              Free
            </Typography>

            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                color: "secondary.main",
                marginBottom: "20px",
              }}
            >
              $0
            </Typography>

            <List>
              {[
                "Unlimited members",
                "Accept payments",
                "0% Platform Fees",
                "Analytics",
              ].map((text, index) => (
                <ListItem key={index} sx={{ padding: "5px 0" }}>
                  <ListItemIcon>
                    <CheckCircleIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>

            <Button
              onClick={() => navigate("/login")}
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: "50px",
                padding: "12px 24px",
                marginTop: "20px",
                transition:
                  "background-color 300ms ease-in-out, transform 150ms ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  transform: "scale(1.05)",
                },
              }}
            >
              Get Started
            </Button>
          </CardContent>
        </Card>
      </Box>
    </DarkSection>
  );
};

export default PricingSection;
