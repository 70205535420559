export const checkGitHubUsername = async (username: string) => {
  try {
    const response = await fetch(`https://api.github.com/users/${username}`);

    if (response.ok) {
      // The user exists
      return true;
    } else if (response.status === 404) {
      // The user does not exist
      return false;
    } else {
      // Handle other potential errors (rate limits, server errors, etc.)
      throw new Error("An error occurred while checking the username.");
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
