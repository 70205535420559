import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  InputAdornment,
  Pagination,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AlertSnackbar,
  AlertSnackbarNotificationProps,
} from "Components/Common/AlertSnackbar";
import { ListingDTO } from "dtos/ListingDTO";
import React, { useEffect, useState } from "react";
import { fetchListingData, fetchRepoData } from "./ListingApiRequests";

// Define the Repo interface
export interface Repo {
  id: number;
  name: string;
  full_name: string;
  private: boolean;
  html_url: string;
}

interface RepoListProps {
  selectedRepo: string | null;
  onSelectRepo: (repoName: string) => void;
}

const RepoList: React.FC<RepoListProps> = ({ selectedRepo, onSelectRepo }) => {
  const [repos, setRepos] = useState<Repo[]>([]);
  const [filteredRepos, setFilteredRepos] = useState<Repo[]>([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AlertSnackbarNotificationProps>({
    open: false,
  });

  const [listings, setListings] = useState<ListingDTO[]>([]);
  const [loadingListings, setLoadingListings] = useState<boolean>(true);

  useEffect(() => {
    fetchRepoData(setRepos, setError, setLoading);
    fetchListingData(setListings, setError, setLoadingListings);
  }, []);

  // Update filteredRepos whenever repos or searchTerm changes
  useEffect(() => {
    const filtered = repos.filter((repo) =>
      repo.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRepos(filtered);
    setPage(1); // Reset page to 1 on search
  }, [repos, searchTerm]);

  // Check if repo is in the listings
  const isRepoInListings = (repoName: string) => {
    return listings.some((listing) => listing.repo === repoName);
  };

  // Handle search term change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Handle radio button change
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const repoName = event.target.value;
    onSelectRepo(repoName);
  };

  // Pagination logic
  const startIndex = (page - 1) * itemsPerPage;
  const paginatedRepos = filteredRepos.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <Box>
      <AlertSnackbar notification={error} setNotification={setError} />
      <TextField
        label="Search Repositories"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <RadioGroup
        value={selectedRepo || ""}
        onChange={handleRadioChange}
        aria-label="Select Repository"
      >
        <Grid container spacing={2}>
          {loading && loadingListings
            ? Array.from({ length: itemsPerPage }).map((_, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Skeleton variant="text" height={40} />
                      <Skeleton variant="text" height={30} />
                      <Skeleton variant="rectangular" height={118} />
                    </CardContent>
                  </Card>
                </Grid>
              ))
            : paginatedRepos.map((repo) => {
                const isDisabled = isRepoInListings(repo.name);
                return (
                  <Grid item xs={12} sm={6} md={4} key={repo.id}>
                    <Tooltip
                      title={
                        isDisabled
                          ? "This repository has been disabled as a listing already exists."
                          : ""
                      }
                    >
                      <Card
                        sx={{ display: "flex", alignItems: "center", p: 2 }}
                      >
                        <span>
                          {/* To wrap the Radio button and handle disabled styling */}
                          <FormControlLabel
                            control={
                              <Radio
                                value={repo.name}
                                color="primary"
                                checked={selectedRepo === repo.name}
                                disabled={isDisabled}
                              />
                            }
                            label={
                              <CardContent>
                                <Typography variant="h6">
                                  {repo.name}
                                </Typography>
                                <a
                                  href={repo.html_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Repository
                                </a>
                              </CardContent>
                            }
                          />
                        </span>
                      </Card>
                    </Tooltip>
                  </Grid>
                );
              })}
        </Grid>
      </RadioGroup>
      {!loading && !loadingListings && filteredRepos.length > 0 && (
        <Pagination
          count={Math.ceil(filteredRepos.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          sx={{ mt: 3, display: "flex", justifyContent: "center" }}
        />
      )}
    </Box>
  );
};

export default RepoList;
