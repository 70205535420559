import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LoginPage from "./Pages/Unprotected/Login";

import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import ProtectedRoute from "Components/Authentication/ProtectedRoute";
import SinglePost from "Components/Sanity/SinglePost";
import Dashboard from "Pages/Protected/Dashboard";
import ListingsPage from "Pages/Protected/Listings";
import ProfilePageSettings from "Pages/Protected/PublicProfileSettings";
import SettingsPage from "Pages/Protected/Settings";
import HomePage from "Pages/Unprotected/Home";
import LogoutPage from "Pages/Unprotected/Logout";
import PublicProfilePage from "Pages/Unprotected/PublicProfilePage";
import PublicProfilePageRepo from "Pages/Unprotected/PublicProfileRepo";
import "./App.css";

// Create a custom theme
const theme = createTheme({
  palette: {
    common: {
      white: "#EAEDF2",
    },
    background: {
      default: "#f5f8fb",
    },
    primary: {
      main: "#262B40",
      light: "#404854",
    },
    secondary: {
      main: "#7BE0FC",
      light: "#66799e",
    },
  },
  typography: {
    fontFamily: '"Nunito Sans", sans-serif',
    h3: {
      fontWeight: 600,
      color: "rgb(38, 43, 64)",
      fontSize: "27px",
      marginBlockEnd: "0px",
      marginBlockStart: "0px",
      marginBottom: "0px",
      marginInlineEnd: "0px",
      marginInlineStart: "0px",
      marginTop: "0px",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Public route accessible to all */}
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/profile/:username" element={<PublicProfilePage />} />
          <Route
            path="/profile/:username/:repo"
            element={<PublicProfilePageRepo />}
          />

          {/* blog */}
          <Route path="/blog/:slug" element={<SinglePost />} />

          {/* locked down to authenticated users */}
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/listings" element={<ListingsPage />} />
            <Route path="/profile" element={<ProfilePageSettings />} />
            <Route path="/settings" element={<SettingsPage />} />
            {/* <Route path="/help" element={<HelpPage />} /> */}
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
