import { Box, Button } from "@mui/material";
import React from "react";

export interface NavigationButtonsProps {
  handleBack?: () => void;
  handleNext?: () => void;
  isNextDisabled?: boolean;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  handleBack,
  handleNext,
  isNextDisabled = false,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        m: 2,
      }}
    >
      {/* Only render the Back button if handleBack is provided */}
      {handleBack && (
        <Button
          onClick={handleBack}
          variant="outlined"
          color="primary"
          sx={{ mr: 2 }}
        >
          Back
        </Button>
      )}

      {/* Only render the Next button if handleNext is provided */}
      {handleNext && (
        <Button
          onClick={handleNext}
          variant="contained"
          color="primary"
          disabled={isNextDisabled}
        >
          Next
        </Button>
      )}
    </Box>
  );
};

export default NavigationButtons;
