import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography } from "@mui/material";
import React from "react";

const PaymentComplete: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: 4,
      }}
    >
      <CheckCircleIcon sx={{ fontSize: 80, color: "green" }} />
      <Typography variant="h4" sx={{ mt: 2, fontWeight: "bold" }}>
        Payment Complete
      </Typography>
    </Box>
  );
};

export default PaymentComplete;
