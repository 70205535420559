import { AlertSnackbarNotificationProps } from "Components/Common/AlertSnackbar";
import { ListingDTO } from "dtos/ListingDTO";
import { fetchUnauthenticated, fetchWithJwt } from "utils/apiRequest";
import { getUserName } from "utils/authentication";
import { Repo } from "./RepoList";

export const fetchRepoData = async (
  setRepos: React.Dispatch<React.SetStateAction<Repo[]>>,
  setError: React.Dispatch<
    React.SetStateAction<AlertSnackbarNotificationProps>
  >,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const response = await fetchWithJwt({
      endpoint: "/user/repos",
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data: Repo[] = await response.json();
    setRepos(data);
  } catch (error) {
    setError({
      open: true,
      message: error.message,
      severity: "error",
    });
  } finally {
    setLoading(false);
  }
};

export const fetchListingByRepo = async (
  setData: React.Dispatch<React.SetStateAction<ListingDTO>>,
  setError: React.Dispatch<
    React.SetStateAction<AlertSnackbarNotificationProps>
  >,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  username: string,
  repo: string
) => {
  try {
    const response = await fetchUnauthenticated({
      endpoint: `/listings/${username}/${repo}`,
    });
    const data = await response.json();
    setData(data);
  } catch (error) {
    setError({
      open: true,
      message: "unable to get the listings",
      severity: "error",
    });
  } finally {
    setLoading(false);
  }
};

export const fetchListingData = async (
  setData: React.Dispatch<React.SetStateAction<ListingDTO[]>>,
  setError: React.Dispatch<
    React.SetStateAction<AlertSnackbarNotificationProps>
  >,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  username = getUserName()
) => {
  try {
    const response = await fetchUnauthenticated({
      endpoint: `/listings/user/${username}`,
    });
    const data = await response.json();
    setData(data);
  } catch (error) {
    setError({
      open: true,
      message: "unable to get your listings",
      severity: "error",
    });
  } finally {
    setLoading(false);
  }
};

export const deleteListing = async (
  setStatus: React.Dispatch<React.SetStateAction<boolean>>,
  setNotification: React.Dispatch<
    React.SetStateAction<AlertSnackbarNotificationProps>
  >,
  listingId: string
) => {
  try {
    const response = await fetchWithJwt({
      endpoint: `/listings/listing/${listingId}`,
      method: "delete",
    });
    await response.json();
    setStatus(true);
    setNotification({
      open: true,
      message: "sucessfully delete listing",
      severity: "success",
    });
  } catch (error) {
    setNotification({
      open: true,
      message: "unable to delete listing",
      severity: "error",
    });
  }
};

export const updateListing = async (
  setNotification: React.Dispatch<
    React.SetStateAction<AlertSnackbarNotificationProps>
  >,
  listing: ListingDTO
) => {
  try {
    const response = await fetchWithJwt({
      endpoint: `/listings/listing/${listing.id}`,
      method: "put",
      body: JSON.stringify(listing),
    });
    await response.json();
    setNotification({
      open: true,
      message: "sucessfully updated listing",
      severity: "success",
    });
  } catch (error) {
    setNotification({
      open: true,
      message: "unable to update listing",
      severity: "error",
    });
  }
};
