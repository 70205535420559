import { Box, CircularProgress } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import CheckoutForm from "Components/Stripe/CheckoutForm";
import { PUBLISHABLE_KEY } from "Components/Stripe/config";
import { ListingDTO } from "dtos/ListingDTO";
import React, { useEffect, useState } from "react";
import { fetchUnauthenticated } from "utils/apiRequest";
import NavigationButtons, { NavigationButtonsProps } from "./NavigationButtons";

export interface IntentIdStateProps {
  intentId: string;
  setIntentId: React.Dispatch<React.SetStateAction<string>>;
}

interface PaymentStepProps {
  githubUserName: string;
  navigationButtons: NavigationButtonsProps;
  listing: ListingDTO;
  intentIdState: IntentIdStateProps;
}

export interface IntentIdStateProps {
  intentId: string;
  setIntentId: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentStep: React.FC<PaymentStepProps> = ({
  githubUserName,
  navigationButtons,
  listing,
  intentIdState,
}) => {
  const stripePromise = loadStripe(PUBLISHABLE_KEY);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [paymentSuccessful, setPaymentSuccessful] = useState<boolean>(false);

  useEffect(() => {
    fetchUnauthenticated({
      endpoint: `/payments/createPaymentIntent/${listing.id}`,
      method: "POST",
      body: JSON.stringify({
        buyerGitHubUsername: githubUserName,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        intentIdState.setIntentId(data.intentId);
        setClientSecret(data.clientSecret);
      });
  }, []);

  const appearance: Appearance = {
    theme: "stripe",
  };

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {stripePromise && clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CheckoutForm
              setPaymentSuccessful={setPaymentSuccessful}
              paymentSuccessful={paymentSuccessful}
              onPaymentSuccess={navigationButtons.handleNext}
            />

            <NavigationButtons
              handleBack={navigationButtons.handleBack}
              handleNext={navigationButtons.handleNext}
              isNextDisabled={!paymentSuccessful}
            />
          </Box>
        </Elements>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default PaymentStep;
