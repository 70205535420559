import XIcon from "@mui/icons-material/X";
import { Box, Link, Typography } from "@mui/material";
import React from "react";

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, rgb(13, 13, 21), #1b0033 70%)",
        padding: "20px",
        textAlign: "center",
        color: "white",
      }}
    >
      <Typography variant="body1">
        &copy; {new Date().getFullYear()} SellCode.dev all rights reserved.
      </Typography>
      <Typography variant="body2">
        <Link
          href="mailto:help@example.com"
          color="inherit"
          underline="hover"
          sx={{ paddingRight: "2px" }}
        >
          Email Me |
        </Link>
        <Link
          href="https://twitter.com/username" // Update with your X account link
          color="inherit"
          underline="hover"
          target="_blank"
          rel="noopener"
        >
          <XIcon
            sx={{
              fontSize: "small",
              verticalAlign: "middle",
              marginRight: "4px",
            }}
          />
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
