import GitHubIcon from "@mui/icons-material/GitHub";
import {
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AlertSnackbar,
  AlertSnackbarNotificationProps,
} from "Components/Common/AlertSnackbar";
import { GitHubCallbackDTO } from "dtos/GitHubCallbackDTO";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { storeJwt } from "utils/authentication";
import { GITHUB_OAUTH_URL, callbackUrl } from "./config.js";
import { useGithubOauthCode } from "./useGithubOauthCode";

interface LoginWithGitHubProps {
  userEmail?: boolean;
  repo?: boolean;
}

const LoginWithGitHub: React.FC<LoginWithGitHubProps> = ({
  userEmail = false,
  repo = false,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  // Generate the scope string based on props
  const scopeParts = [];
  if (userEmail) scopeParts.push("user:email");
  if (repo) scopeParts.push("repo");
  const scope = scopeParts.join(" ");

  const params = new URLSearchParams({
    scope: scope,
  });

  const code = useGithubOauthCode();
  const [loading, setLoading] = useState(false);
  const [problemLoggingIn, setProblemLoggingIn] =
    useState<AlertSnackbarNotificationProps>({
      open: false,
    });

  useEffect(() => {
    if (code !== undefined) {
      setLoading(true);
      const requestBody: GitHubCallbackDTO = { code: code };

      fetch(callbackUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify(requestBody), // Convert the body to a JSON string
      })
        .then((response) => {
          if (response.ok) {
            return response.json(); // Return the JSON promise
          } else {
            throw new Error(
              "Failed to fetch user data from API after authenticating with GitHub"
            );
          }
        })
        .then((data) => {
          const jwtToken = data.jwt;
          if (jwtToken) {
            // Store the JWT in session storage
            storeJwt(jwtToken);
            setLoading(false);
            navigate("/dashboard");
          } else {
            throw new Error("No JWT received");
          }
        })
        .catch((error) => {
          setLoading(false);
          setProblemLoggingIn({
            open: true,
            severity: "error",
            message: error.message,
          });
        });
    }
  }, [code, navigate]);

  return (
    <>
      <AlertSnackbar
        notification={problemLoggingIn}
        setNotification={setProblemLoggingIn}
      />
      <IconButton
        href={`${GITHUB_OAUTH_URL}&${params.toString()}`}
        sx={{
          color: "white",
          padding: 1,
          borderRadius: 1,
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
          minHeight: "44px",
        }}
      >
        {loading ? (
          <CircularProgress sx={{ color: "white" }} size={20} />
        ) : (
          <>
            <GitHubIcon sx={{ fontSize: 24, marginRight: 1 }} />
            <Typography
              variant="button"
              sx={{ fontSize: 16, textTransform: "none" }}
            >
              Sign in with GitHub
            </Typography>
          </>
        )}
      </IconButton>
    </>
  );
};

export default LoginWithGitHub;
