import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AlertSnackbar,
  AlertSnackbarNotificationProps,
} from "Components/Common/AlertSnackbar";
import { ListingDTO } from "dtos/ListingDTO";
import React, { useEffect, useState } from "react";
import EditListing from "./EditListing";
import { deleteListing, fetchListingData } from "./ListingApiRequests";

const ManageListings: React.FC = () => {
  const [deleteStatus, setDeleteStatus] = useState<boolean>();
  const [records, setRecords] = useState<ListingDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] =
    useState<AlertSnackbarNotificationProps>({
      open: false,
    });

  const [editRecord, setEditRecord] = useState<ListingDTO | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  useEffect(() => {
    fetchListingData(setRecords, setNotification, setLoading);
  }, [deleteStatus]);

  const handleEdit = (id: string) => {
    const recordToEdit = records.find((record) => record.id === id);
    if (recordToEdit) {
      setEditRecord(recordToEdit);
      setOpenEditDialog(true);
    }
  };

  const handleDelete = (id: string) => {
    deleteListing(setDeleteStatus, setNotification, id);
  };

  const handleSaveEdit = (
    id: string,
    updatedRecord: { description: string; price: number }
  ) => {
    setRecords((prev) =>
      prev.map((record) =>
        record.id === id ? { ...record, ...updatedRecord } : record
      )
    );
    setOpenEditDialog(false);
  };

  return (
    <>
      <AlertSnackbar
        notification={notification}
        setNotification={setNotification}
      />
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          boxShadow: 3,
          overflow: "hidden",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Repository</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from(new Array(5)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" width={100} height={40} />
                  </TableCell>
                </TableRow>
              ))
            ) : records.length > 0 ? (
              records.map((record) => (
                <TableRow key={record.id}>
                  <TableCell>{record.repo}</TableCell>
                  <TableCell>{record.description}</TableCell>
                  <TableCell>${record.price}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ mr: 1 }}
                      onClick={() => handleEdit(record.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ borderColor: "red" }}
                      onClick={() => handleDelete(record.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography align="center">
                    You have no repositories listed
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Listing Dialog */}
      <EditListing
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        record={editRecord}
        onSave={handleSaveEdit}
        setNotification={setNotification}
      />
    </>
  );
};

export default ManageListings;
