export const faqData = [
  {
    question: "How does it work?",
    answer: `We’ve integrated with GitHub to streamline the process of granting access to private repositories. To get started, you’ll create a listing for one of your private repositories, which we’ll automatically pull in for you. After setting a price and a description, you can share your personalized URL for all your listings or for a specific repository. Once someone purchases access, they will be automatically added to the repository (as long as their payment is successful).`,
  },
  {
    question: "How fast and easy is it to set up?",
    answer: `It’s simple! Just log in with your GitHub account and we’ll take care of the rest. 
             You’ll also need to set up your payout details using the Stripe link in the "Settings" section after you log in.`,
  },
  {
    question: "How do payouts work?",
    answer: `We use Stripe Connect (https://stripe.com/connect) to handle payments. 
             When you make a sale, Stripe will deduct their transaction fee and send the remaining amount to your account. 
             You can then request a payout directly through your Stripe dashboard.`,
  },
  {
    question: "Free forever, really?",
    answer: `Yes, really! We’ll never charge you an application fee for any listings you create during this period.`,
  },
  {
    question: "Have you got an example listings page I can look at?",
    answer: `Yes, certainly. Check out https://sellcode.dev/profile/JacobAnderson0`,
  },
];
