import { Typography } from "@mui/material";
import CardPanel from "Components/Common/CardPanel";
import React from "react";

interface SingleValueWidgetProps {
  title: any;
  description: string;
  value: string;
}

export const SingleVlaueWidget: React.FC<SingleValueWidgetProps> = ({
  title,
  description,
  value,
}) => {
  return (
    <CardPanel title={title} description={description} compact>
      <Typography sx={{ fontSize: " xxx-large", textAlign: "center" }}>
        {value}
      </Typography>
    </CardPanel>
  );
};
