import { Button, ButtonGroup, styled } from "@mui/material";
import React from "react";

export interface ToggleButtonGroupProps {
  labels: string[]; // List of button labels
  selected: string; // Currently selected label
  onSelect: (selection: string) => void; // Callback for button selection
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected: boolean }>(({ theme, selected }) => ({
  backgroundColor: selected
    ? theme.palette.primary.main
    : theme.palette.background.default,
  color: selected ? theme.palette.common.white : theme.palette.primary.main,
  "&:hover": {
    backgroundColor: selected
      ? theme.palette.primary.dark
      : theme.palette.background.paper,
  },
}));

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  labels,
  selected,
  onSelect,
}) => {
  return (
    <ButtonGroup
      variant="contained"
      aria-label="toggle-buttons"
      sx={{ marginBottom: "1%" }}
    >
      {labels.map((label) => (
        <StyledButton
          key={label}
          selected={selected === label}
          onClick={() => onSelect(label)}
        >
          {label}
        </StyledButton>
      ))}
    </ButtonGroup>
  );
};

export default ToggleButtonGroup;
