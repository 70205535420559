import { Box, SwipeableDrawer } from "@mui/material";
import { ListingDTO } from "dtos/ListingDTO";
import React from "react";
import BuyNowStepper from "./Stepper/BuyNowStepper";

export interface BuyNowOrderDrawProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  listing: ListingDTO;
}

const BuyNowOrderDraw: React.FC<BuyNowOrderDrawProps> = ({
  drawerOpen,
  setDrawerOpen,
  listing,
}) => {
  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  return (
    <Box>
      {/* SwipeableDrawer Component */}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{
            height: "80vh",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <BuyNowStepper onClose={toggleDrawer(false)} listing={listing}/>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default BuyNowOrderDraw;
