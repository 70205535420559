import Box from "@mui/material/Box";
import Seo from "Components/Common/Seo";
import Repo from "Components/Profile/PublicPage/RepoView";
import React from "react";
import { useParams } from "react-router-dom";

const PublicProfilePageRepo = () => {
  const { username, repo } = useParams();

  return (
    <Box>
      <Seo
        title={`${repo} | ${username}`}
        description={`Code avaliable from ${username}`}
      />
      <Repo username={username} repo={repo} />
    </Box>
  );
};

export default PublicProfilePageRepo;
