import { jwtDecode } from "jwt-decode";

export interface JwtPayload {
  email: string;
  exp: number; // Expiration timestamp (in seconds)
  iat: number; // Issued at timestamp (in seconds)
  name: string;
  sub: string;
  username: string;
}

export const storeJwt = (jwtToken: string) => {
  sessionStorage.setItem("jwtToken", jwtToken);
};

export const getJwt = (): string | null => sessionStorage.getItem("jwtToken");

export const decodeJwt = (): JwtPayload | null => {
  const jwt = getJwt();
  if (!jwt) return null;
  try {
    return jwtDecode<JwtPayload>(jwt);
  } catch (error) {
    console.error("Invalid JWT:", error);
    return null;
  }
};

export const userLoggedIn = (): boolean => {
  const decodedJwt = decodeJwt();
  if (!decodedJwt) return false;
  const now = Math.floor(Date.now() / 1000);
  // Check if the JWT is expired
  return decodedJwt.exp > now;
};

export const getName = (): string | null => {
  const decodedJwt = decodeJwt();
  return decodedJwt ? decodedJwt.name : null;
};

export const getUserName = (): string | null => {
  const decodedJwt = decodeJwt();
  return decodedJwt ? decodedJwt.username : null;
};

export const getEmail = (): string | null => {
  const decodedJwt = decodeJwt();
  return decodedJwt ? decodedJwt.email : null;
};

export const logout = () => {
  sessionStorage.clear();
  window.location.href = "/login";
};
