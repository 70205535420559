// DashboardPage.tsx
import { Box, Typography } from "@mui/material";
import TopBar from "Components/Dashboard/TopBar";
import Navigation from "Components/Navigation/Navigation";
import React, { ReactNode } from "react";
import { toTitleCase } from "utils/stringUtils";
import { firstSegmentPath } from "utils/url";
import Seo from "./Seo";

interface DashboardPageProps {
  title?: string;
  children: ReactNode;
}

export default function DashboardPage({ title, children }: DashboardPageProps) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Seo
        title={toTitleCase(firstSegmentPath)}
        description={"The Best Place To Sell Code"}
      />
      <TopBar />

      <Box sx={{ display: "flex", flexGrow: 1 }}>
        {/* Navigation Drawer */}
        <Navigation />

        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: 3,
            marginLeft: "1px",
            paddingTop: "0px",
          }}
        >
          {title && (
            <Typography variant="h1" sx={{ marginBottom: 3 }}>
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Box>
    </Box>
  );
}
