import Box from "@mui/material/Box";
import Seo from "Components/Common/Seo";
import PublicProfile from "Components/Profile/PublicPage/PublicProfile";
import React from "react";
import { useParams } from "react-router-dom";

const ProfilePage = () => {
  const { username } = useParams();

  return (
    <Box>
      <Seo title={username} description={`Code avaliable from ${username}`} />
      <PublicProfile username={username} />
    </Box>
  );
};

export default ProfilePage;
