import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { faqData } from "./faqConfig"; // Import the FAQ data
import LightSection from "./LightSection";

const FAQSection: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Trigger animation only when coming into view
            setAnimate(true);
          } else {
            // Optional: You can reset animation state if desired.
            setAnimate(false); // Remove this line to maintain the final state
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    // Copy the current ref value to a local variable
    const currentSectionRef = sectionRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  // Function to parse and render links in the answer text
  const parseAnswer = (answer: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Regular expression to detect URLs
    const parts = answer.split(urlRegex); // Split text into parts by URLs

    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        // If the part matches the URL pattern, render as a link
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff" }}
          >
            {part}
          </a>
        );
      }
      // Otherwise, return the text as-is
      return part;
    });
  };

  return (
    <LightSection
      id="faqs"
      ref={sectionRef} // Reference for IntersectionObserver
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "60%" }, // Responsive width
          margin: "0 auto", // Center the Box
          textAlign: "center", // Center the text content inside
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "40px", sm: "50px" },
            marginBottom: "20px", // Space between heading and card
            opacity: animate ? 1 : 0,
            transform: animate ? "translateY(0)" : "translateY(-30px)",
            transition:
              "opacity 700ms ease-in-out, transform 700ms ease-in-out",
          }}
        >
          FAQs.
        </Typography>

        {/* Map through the FAQ data from the config file */}
        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            sx={{ marginBottom: "10px", opacity: animate ? 1 : 0 }} // Maintain visibility
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq${index}-content`}
              id={`faq${index}-header`}
            >
              <Typography
                variant="h6"
                sx={{
                  opacity: animate ? 1 : 0,
                  transform: animate ? "translateY(0)" : "translateY(-10px)",
                  transition:
                    "opacity 300ms ease-in-out, transform 300ms ease-in-out",
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                sx={{
                  opacity: animate ? 1 : 0,
                  transform: animate ? "translateY(0)" : "translateY(-10px)",
                  transition:
                    "opacity 300ms ease-in-out, transform 300ms ease-in-out",
                }}
              >
                {parseAnswer(faq.answer)} {/* Use the parseAnswer function */}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </LightSection>
  );
};

export default FAQSection;
