import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";

const BackToHomePage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Typography
      variant="h5"
      textAlign="center"
      sx={{
        display: "flex",
        alignItems: "center", // Vertically align the items
        justifyContent: "center", // Center align the items horizontally
        color: "#66799E",
        fontSize: "16px",
        fontWeight: 400,
        cursor: "pointer", // Show pointer cursor to indicate it's clickable
      }}
      onClick={handleClick} // Handle the click event
    >
      <ArrowBackIosIcon sx={{ fontSize: "16px", marginRight: "8px" }} />
      Back to homepage
    </Typography>
  );
};

export default BackToHomePage;
