import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { userLoggedIn } from "utils/authentication";

interface Props {
  admin?: Boolean;
}

const ProtectedRoute = (props: Props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        setLoggedIn(userLoggedIn());
      } catch (error) {
        console.error("Error checking current user:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    checkCurrentUser();
  }, [props.admin]);

  if (loading) {
    // While loading, display a loading indicator or message
    return;
  }

  // After loading, render the appropriate content based on the loggedIn state
  return loggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
