import { Box, Typography } from "@mui/material";
import HowToGuidesCards from "Components/Sanity/HowToGuidesCards";
import React, { useEffect, useRef, useState } from "react";
import DarkSection from "./DarkSection";
import MonitizeWordRotator from "./MonitizeWordRotator";

const HowToGuides = () => {
  const [animate, setAnimate] = useState(false);
  const sectionRef = useRef<HTMLDivElement | null>(null); // Ref for the section

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true); // Trigger animation when in view
        } else {
          setAnimate(false); // Reset animation when out of view
        }
      },
      { threshold: 0.3 } // Adjust the threshold based on when you want the animation to start
    );

    const currentSectionRef = sectionRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef); // Observe the section
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef); // Clean up observer on unmount
      }
    };
  }, []);

  return (
    <DarkSection id="HowToGuides" ref={sectionRef}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          padding: "40px 0",
          width: "90%",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            display: "inline",
            fontWeight: "bold",
            marginBottom: "4%",
            opacity: animate ? 1 : 0, // Fade-in effect for heading
            transform: animate ? "translateY(0)" : "translateY(20px)", // Slide up
            transition:
              "opacity 700ms ease-in-out, transform 700ms ease-in-out",
          }}
        >
          How To Monetize {<MonitizeWordRotator />}
        </Typography>

        <HowToGuidesCards />
      </Box>
    </DarkSection>
  );
};

export default HowToGuides;
