import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { AlertBox, AlertNotificationProps } from "Components/Common/AlertBox";
import React, { useState } from "react";
import { fetchWithJwt } from "utils/apiRequest";
import { getUserName } from "utils/authentication";
import { ListingDTO } from "../../dtos/ListingDTO";
import ConfigureListing from "./ConfigureListing";
import RepoList from "./RepoList";

const SellStepper: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedRepo, setSelectedRepo] = useState<string | null>(null);
  const [config, setConfig] = useState<ListingDTO | null>(null);
  const [alertMessage, setAlertMessage] =
    useState<AlertNotificationProps | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // For handling submission state
  const steps = ["Select A Repository", "Configure for Sale", "Publish"];

  // Handle step change
  const handleNext = async () => {
    if (activeStep === 0 && selectedRepo === null) {
      setAlertMessage({
        message: "Please select a repository before proceeding.",
        severity: "warning",
      });
      return;
    }
    if (activeStep === 1 && !config) {
      setAlertMessage({
        message: "Please complete the configuration before proceeding.",
        severity: "warning",
      });
      return;
    }
    if (activeStep === steps.length - 1) {
      // Submit the listing on the last step
      await handleSubmit();
      return;
    }
    setAlertMessage(null); // Clear any previous alert message
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setAlertMessage(null); // Clear any previous alert message
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Handle repo selection
  const handleSelectRepo = (repoName: string) => {
    setSelectedRepo(repoName);
  };

  // Handle configuration save
  const handleSaveConfig = (configuration: ListingDTO) => {
    setConfig(configuration);
  };

  const handleAddAnotherListing = () => {
    setAlertMessage(null);
    setActiveStep(0);
  };

  // Submit the listing
  const handleSubmit = async () => {
    if (!selectedRepo || !config) {
      setAlertMessage({
        message: "Repository and configuration are required to submit.",
        severity: "warning",
      });
      return;
    }

    setIsSubmitting(true);
    setAlertMessage(null);

    try {
      await fetchWithJwt({
        endpoint: `/listings/user/${getUserName()}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          repo: selectedRepo,
          price: config.price,
          description: config.description,
        }),
      });

      // Handle success, e.g., show a success message or redirect
      setAlertMessage({
        message: "Listing submitted successfully!",
        severity: "success",
      });
      setActiveStep(steps.length); // Move to finish step or success page
    } catch (error) {
      setAlertMessage({
        message: "There was an error submitting your listing.",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <RepoList
            selectedRepo={selectedRepo}
            onSelectRepo={handleSelectRepo}
          />
        );
      case 1:
        return <ConfigureListing onSave={handleSaveConfig} />;
      case 2:
        return (
          <>
            <Typography variant="subtitle1" color="textSecondary">
              Selected Repo:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {selectedRepo || "No repository selected"}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Configuration:
            </Typography>
            {config ? (
              <>
                <Typography variant="body2">
                  <strong>Price:</strong> ${config.price}
                </Typography>
                <Typography variant="body2">
                  <strong>Description:</strong> {config.description}
                </Typography>
              </>
            ) : (
              <Typography variant="body2" color="error">
                Not configured
              </Typography>
            )}
          </>
        );
      case 3:
        return (
          <Button
            variant="contained"
            fullWidth
            onClick={handleAddAnotherListing}
          >
            Add another listing
          </Button>
        );
      default:
        return <Typography>Unknown Step</Typography>;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ p: 3 }}>
        <AlertBox alert={alertMessage} />
        {renderStepContent(activeStep)}
      </Box>
      {activeStep !== steps.length && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            disabled={activeStep === 0 || isSubmitting}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={isSubmitting}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SellStepper;
