import LogoutIcon from "@mui/icons-material/Logout";
import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { UserDTO } from "dtos/UserDTO";
import React, { useEffect, useState } from "react";
import { getName, logout } from "utils/authentication";
import ProfileImage from "./ProfileImage";
import ProfileName from "./ProfileName";
import { fetchUserData } from "./profileApiRequest";

const ProfileWithMenu = () => {
  const theme = useTheme();
  const [user, setUser] = useState<UserDTO | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const name = getName();

  useEffect(() => {
    fetchUserData(setUser);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLogout = () => {
    logout();
  };

  if (!user) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        padding: 1,
        position: "relative",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <ProfileImage src={user?.profilePictureUrl} />
      <ProfileName name={name} />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        disableAutoFocusItem
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            mt: 1.5,
            display: "flex",
            justifyContent: "center",
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleLogout}
          sx={{
            justifyContent: "center",
            fontWeight: 600,
            fontSize: "14px",
            "&:hover": {
              backgroundColor: "#f5f8fb",
            },
            color: theme.palette.secondary.light,
          }}
        >
          <LogoutIcon fontSize="small" sx={{ marginRight: "15px" }} /> Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileWithMenu;
