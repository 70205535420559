import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { PostType } from "./types";

interface PostCardProps {
  post: PostType;
}

const PostCard: React.FC<PostCardProps> = ({ post }) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <Card
      ref={cardRef}
      sx={{
        width: "100%",
        height: "100%",
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.5s ease, transform 0.5s ease",
      }}
    >
      <CardMedia
        sx={{ height: 300 }}
        image={post?.mainImage?.asset.url}
        title={post?.mainImage?.alt}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {post.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {post.description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center", // Center the button horizontally
          width: "100%", // Ensure the actions area takes the full width
        }}
      >
        <Button
          size="small"
          href={`/blog/${post?.slug?.current}`}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            color: "white",
            padding: "10px 15px",
            borderRadius: "20px",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.dark,
            },
            width: "70%", // Set the button width to 70%
          }}
        >
          Read
        </Button>
      </CardActions>
    </Card>
  );
};

export default PostCard;
