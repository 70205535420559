import { ProfileDTO } from "dtos/ProfileDTO";
import { UserDTO } from "dtos/UserDTO";
import { fetchUnauthenticated, fetchWithJwt } from "utils/apiRequest";
import { getUserName, userLoggedIn } from "utils/authentication";

export const fetchUserData = async (
  setUser: React.Dispatch<React.SetStateAction<UserDTO>>
) => {
  try {
    const response = await fetchWithJwt({
      endpoint: "/user",
      method: "GET",
    });

    const data = await response.json();
    setUser(data);
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};

export const fetchUserPublicProfileData = async (
  setUser: React.Dispatch<React.SetStateAction<ProfileDTO>>,
  username = getUserName(),
  authenticated = userLoggedIn()
) => {
  try {
    if (authenticated) {
      const response = await fetchWithJwt({
        endpoint: `/profile/${username}`,
        method: "GET",
      });
      const data = await response.json();
      setUser(data);
    } else {
      const response = await fetchUnauthenticated({
        endpoint: `/profile/${username}`,
        method: "GET",
      });
      const data = await response.json();
      setUser(data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};
