import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DarkSection from "./DarkSection"; // Import the DarkSection component
import "./banner.css";

const Banner = () => {
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(false);
  const sectionRef = useRef<HTMLDivElement | null>(null); // Create a ref for the section

  // Use IntersectionObserver to trigger animation when in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true); // Start the animation
        } else {
          setAnimate(false); // Reset the animation when out of view
        }
      },
      { threshold: 0.3 } // Adjust the threshold as needed
    );

    // Copy the current ref value to a local variable
    const currentSectionRef = sectionRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef); // Observe the banner section
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef); // Clean up observer
      }
    };
  }, []);

  return (
    <DarkSection id="banner" ref={sectionRef}>
      <Box
        className={`banner-content ${animate ? "fade-in" : ""}`}
        sx={{
          textAlign: "center",
          opacity: 1,
          transition: "opacity 700ms ease-in-out",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: "60px", sm: "100px", lg: "115px" },
            fontWeight: "600",
            color: "white",
            letterSpacing: "-0.025em",
            lineHeight: "88%",
            marginBottom: "15px",
            opacity: animate ? 1 : 0,
            transform: animate ? "translateY(0)" : "translateY(-20px)",
            transition:
              "opacity 900ms ease-in-out, transform 900ms ease-in-out",
          }}
        >
          Sell Access <br /> To GitHub Repos
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.2rem",
            color: "white",
            mb: 3,
            opacity: animate ? 1 : 0,
            transform: animate ? "translateY(0)" : "translateY(-10px)",
            transition:
              "opacity 900ms ease-in-out, transform 900ms ease-in-out",
            transitionDelay: "300ms", // Staggered animation
          }}
        >
          Monetize your GitHub repositories by selling access for cash. <br />
          Secure, simple, and profitable.
        </Typography>
        <Button
          onClick={() => navigate("/login")}
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: "50px",
            padding: "12px 24px",
            width: "70%",
            opacity: animate ? 1 : 0,
            transform: animate ? "translateY(0)" : "translateY(20px)",
            transition:
              "opacity 900ms ease-in-out, transform 900ms ease-in-out",
            transitionDelay: "600ms", // Staggered button animation
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              transform: "scale(1.05)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          Get Started
        </Button>
      </Box>
    </DarkSection>
  );
};

export default Banner;
