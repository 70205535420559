import { Box, Typography } from "@mui/material";
import React from "react";

interface FeatureSectionProps {
  imagePath: string;
  title: string;
  description: string;
}

const FeatureSection: React.FC<FeatureSectionProps> = ({
  imagePath,
  title,
  description,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#f6f8fb",
        display: "flex",
        flexDirection: "column", // Keep image above text
        alignItems: "center",
        justifyContent: "center",
        padding: "40px",
        marginBottom: "20px",
        borderRadius: "16px",
        boxShadow: "none",
        textAlign: "center",
      }}
    >
      <Box
        component="img"
        src={imagePath}
        alt={title}
        sx={{
          height: "300px",
          width: "300px",
          marginBottom: "20px", // Maintain spacing between image and text
          borderRadius: "8px",
        }}
      />
      <Box>
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "rgb(136, 136, 136)" }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default FeatureSection;
