import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Seo from "Components/Common/Seo";
import FloatingNavBar, {
  ActionButton,
} from "Components/HomePage/FloatingNavBar";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import client from "./sanityClient";
import { PostType } from "./types";

export const SinglePost = () => {
  const { slug } = useParams<{ slug: string }>();
  const [postData, setPostData] = useState<PostType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!slug) return;

    client
      .fetch(
        `*[_type == "post" && slug.current == $slug][0]{
          title,
          slug,
          mainImage {
            asset-> {
              _id,
              url
            },
            alt
          },
          body
        }`,
        { slug }
      )
      .then((data: PostType) => {
        setPostData(data);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.error(err);
        setError("Failed to fetch post.");
        setLoading(false);
      });
  }, [slug]);

  const renderContent = () => {
    if (loading)
      return (
        <Box mb={4}>
          <Skeleton variant="rectangular" width="100%" height={300} />
          <Skeleton variant="text" width="80%" height={50} sx={{ mt: 2 }} />
          <Skeleton variant="text" width="60%" height={20} sx={{ mt: 1 }} />
          <Skeleton variant="text" width="90%" height={20} sx={{ mt: 1 }} />
          <Skeleton variant="text" width="95%" height={20} sx={{ mt: 1 }} />
          <Skeleton variant="text" width="85%" height={20} sx={{ mt: 1 }} />
        </Box>
      );

    if (error) return <Typography color="error">{error}</Typography>;
    if (!postData) return <Typography>Post not found.</Typography>;

    return (
      <Paper elevation={3} sx={{ padding: 3, mb: 4 }}>
        <Seo description={postData?.description} title={postData?.title} />
        <FloatingNavBar />
        {postData.mainImage?.asset?.url && (
          <Box mb={2} sx={{ maxWidth: "100%", mx: "auto" }}>
            <img
              src={postData.mainImage.asset.url}
              alt={postData.mainImage.alt}
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
          </Box>
        )}
        <Typography variant="h4" component="h1" gutterBottom>
          {postData.title}
        </Typography>

        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {postData.body}
        </ReactMarkdown>

        <Box
          sx={{
            mt: 4,
            textAlign: "center", // Centering the CTA
            padding: 2,
            border: "1px solid #262B40", // Optional border for emphasis
            borderRadius: "8px",
            backgroundColor: "#f5f8fb", // Light background for the CTA section
          }}
        >
          <Typography variant="h6" gutterBottom>
            Ready to start making some money?
          </Typography>
          <ActionButton
            key="learn more"
            onClick={() => navigate("/")}
            sx={{
              marginRight: "10px",
            }}
          >
            Learn More
          </ActionButton>
          <ActionButton key="get started" onClick={() => navigate("/login")}>
            Get Started
          </ActionButton>
        </Box>
      </Paper>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box>{renderContent()}</Box>
    </Container>
  );
};

export default SinglePost;
