import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SellIcon from "@mui/icons-material/Sell";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Logo from "Components/Common/Logo";
import React, { useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import accountConfig from "./accountConfig.json";
import navConfig from "./navConfig.json";

type IconMap = {
  [key: string]: React.ReactElement;
};

const icons: IconMap = {
  DashboardIcon: <DashboardIcon />,
  SellIcon: <SellIcon />,
  PersonIcon: <PersonIcon />,
  SettingsIcon: <SettingsIcon />,
  HelpIcon: <HelpIcon />,
  LogoutIcon: <LogoutIcon />,
};

const Navigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const location = useLocation(); // Get the current location

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      {/* Mobile Menu Button */}
      {isMobile && (
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            zIndex: 1201, // Ensure button is above the Drawer
          }}
          aria-label={open ? "Close menu" : "Open menu"}
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      )}

      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? open : true}
        onClose={handleDrawerToggle}
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 240,
            boxSizing: "border-box",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
      >
        <List>
          <Logo />
          {navConfig.map((item, index) => (
            <ListItemButton
              key={index}
              sx={{
                backgroundColor:
                  location.pathname === item.path
                    ? theme.palette.secondary.main
                    : "none",
                borderRadius: "8px",
                margin: "8px",
                color:
                  location.pathname === item.path
                    ? theme.palette.primary.main
                    : theme.palette.common.white,
                border:
                  location.pathname === item.path
                    ? `2px solid ${theme.palette.secondary.main}`
                    : "none",
                "&:hover": {
                  backgroundColor:
                    location.pathname === item.path
                      ? theme.palette.secondary.main
                      : theme.palette.primary.light,
                },
              }}
              href={item.path}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === item.path
                      ? theme.palette.primary.main
                      : theme.palette.common.white,
                }}
              >
                {icons[item.icon]}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}

          {/* Account Management */}
          {accountConfig.length > 0 && (
            <>
              <Divider
                variant="middle"
                sx={{
                  backgroundColor: theme.palette.common.white,
                  opacity: "35%",
                }}
              />
              {accountConfig.map((item, index) => (
                <ListItemButton
                  key={index}
                  sx={{
                    borderRadius: "8px",
                    margin: "8px",
                    color: theme.palette.common.white,
                    border:
                      location.pathname === item.path
                        ? `2px solid ${theme.palette.secondary.main}`
                        : "none",
                    "&:hover": {
                      backgroundColor:
                        location.pathname === item.path
                          ? "none"
                          : theme.palette.primary.light,
                    },
                  }}
                  href={item.path}
                >
                  <ListItemIcon
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  >
                    {icons[item.icon]}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              ))}
            </>
          )}
        </List>
      </Drawer>
    </>
  );
};

export default Navigation;
