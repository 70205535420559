import { AlertNotificationProps } from "Components/Common/AlertBox";
import { fetchWithJwt } from "utils/apiRequest";

export const fetchAccountPayoutStatus = async (
  setStatus: React.Dispatch<React.SetStateAction<boolean>>,
  setAlertMessage: React.Dispatch<React.SetStateAction<AlertNotificationProps>>
) => {
  try {
    const response = await fetchWithJwt({
      endpoint: "/payments/accountStatus",
      method: "GET",
    });

    const data = await response.json();

    if (data?.status === "enabled") {
      setStatus(true);
    } else if (data?.status === "disabled") {
      setStatus(false);
      setAlertMessage({
        message:
          "your connection with stripe isn't completed, try again and completing the required information.",
        severity: "warning",
      });
    } else {
      setStatus(false);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
    setStatus(false);
  }
};

export const getStripeConnectConfigureLink = async () => {
  try {
    const response = await fetchWithJwt({
      endpoint: "/payments/connectAccount",
      method: "GET",
      customResponseHandling: true,
    });

    if (!response.ok) {
      // Handle non-200 responses
      const error = await response.json();
      return [false, error.message];
    }

    const data = await response.json();
    return [true, data?.connectUrl];
  } catch (error) {
    console.error("Error fetching profile data:", error);
    return null;
  }
};
