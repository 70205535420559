import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FeatureSection from "./FeatureSection";
import LightSection from "./LightSection";

const Features = () => {
  const [animate, setAnimate] = useState(false);
  const sectionRef = useRef<HTMLDivElement | null>(null); // Ref for the section

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setAnimate(true); // Trigger animation when in view
        } else {
          setAnimate(false); // Reset animation when out of view
        }
      },
      { threshold: 0.3 } // Adjust the threshold based on when you want the animation to start
    );

    // Copy the current ref value to a local variable
    const currentSectionRef = sectionRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef); // Observe the section
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef); // Clean up observer on unmount
      }
    };
  }, []);

  const features = [
    {
      imagePath: "images/add_user_feature.png",
      title: "Automated Member Adding",
      description:
        "Automatically adds users to your repository when payment is successful.",
    },
    {
      imagePath: "images/stripe_feature.png",
      title: "Payments Handled By Stripe",
      description:
        "We are using Stripe connect so you know payments are handled properly.",
    },
    {
      imagePath: "images/analytics.png",
      title: "Analytics",
      description:
        "Data is everything, that is why you can view your data in our purpose-built dashboards or export them.",
    },
  ];

  return (
    <LightSection id="features" ref={sectionRef}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          padding: "40px 0",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            marginBottom: "10px",
            opacity: animate ? 1 : 0, // Fade-in effect for heading
            transform: animate ? "translateY(0)" : "translateY(20px)", // Slide up
            transition:
              "opacity 700ms ease-in-out, transform 700ms ease-in-out",
          }}
        >
          Features.
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: "rgba(0, 0, 0, 0.7)",
            opacity: animate ? 1 : 0, // Fade-in effect for subtitle
            transform: animate ? "translateY(0)" : "translateY(20px)", // Slide up
            transition:
              "opacity 700ms ease-in-out, transform 700ms ease-in-out",
            transitionDelay: "200ms", // Delay subtitle to stagger animation
          }}
        >
          Explore the amazing features of our platform.
        </Typography>

        <Grid container spacing={2} justifyContent="center">
          {features.map((feature, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                opacity: animate ? 1 : 0, // Fade-in effect for each feature
                transform: animate ? "scale(1)" : "scale(0.9)", // Zoom-in effect
                transition:
                  "opacity 700ms ease-in-out, transform 700ms ease-in-out",
                transitionDelay: `${index * 200}ms`, // Stagger the zoom-in effect
              }}
            >
              <FeatureSection
                imagePath={feature.imagePath}
                title={feature.title}
                description={feature.description}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </LightSection>
  );
};

export default Features;
