import { Container, Paper, Typography } from "@mui/material";
import appConfig from "appConfig";
import BackToHomePage from "Components/Common/BackToHomePage";
import LoginWithGitHub from "Components/GitHubAuthentication/LoginWithGitHub";
import React from "react";

export default function LoginPage() {
  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column", // Align items vertically
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: 2, // Add padding if needed
      }}
    >
      <BackToHomePage />

      <Paper
        elevation={3}
        sx={{
          padding: 4,
          borderRadius: 3,
          width: "50%",
          mt: 2, // Margin top to separate from BackToHomePage
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          textAlign="center"
          sx={{ marginBottom: "5%" }}
        >
          Sign in to {appConfig.app_name}
        </Typography>
        <LoginWithGitHub userEmail repo />
      </Paper>
    </Container>
  );
}
