import {
  Skeleton,
  SxProps,
  Theme,
  Typography,
  TypographyVariant,
} from "@mui/material";
import React from "react";

interface ProfileNameProps {
  name?: string; // Make name optional
  sx?: SxProps<Theme>;
  variant?: TypographyVariant;
}

const ProfileName: React.FC<ProfileNameProps> = ({
  name,
  sx,
  variant = "body1",
}) => {
  return (
    <>
      {name ? (
        <Typography
          variant={variant}
          sx={{
            color: (theme: Theme) => theme.palette.primary.main,
            fontWeight: 600,
            ...sx,
          }}
        >
          {name}
        </Typography>
      ) : (
        <Skeleton
          variant="text"
          width={200}
          height={40}
          sx={{
            borderRadius: 1,
            ...sx,
          }}
        />
      )}
    </>
  );
};

export default ProfileName;
