import CardPanel from "Components/Common/CardPanel";
import PageTemplate from "Components/Common/PageTemplate";
import { SellerSettings } from "Components/Settings/Seller/SellerSettings";
import React from "react";

export default function SettingsPage() {
  return (
    <PageTemplate>
      <CardPanel title="Settings">
        <SellerSettings />
      </CardPanel>
    </PageTemplate>
  );
}
