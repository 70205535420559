import React from "react";
import { Helmet } from "react-helmet";

import appConfig from "appConfig";

interface SEOProps {
  title?: string;
  description?: string;
}

const Seo: React.FC<SEOProps> = ({ title, description }) => {
  return (
    <Helmet>
      {/* Set the dynamic title */}
      {title && <title>{`${title} | ${appConfig.seo.title}`}</title>}

      {/* Set the dynamic meta description */}
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};

export default Seo;
