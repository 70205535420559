import { Typography } from "@mui/material";
import React from "react";

interface AboutMeProps {
  text: string;
}

const AboutMe: React.FC<AboutMeProps> = ({ text }) => {
  return (
    <Typography variant="body2" sx={{ textAlign: "center" }}>
      {text}
    </Typography>
  );
};

export default AboutMe;
