import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useGithubOauthCode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = useState<string>();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const userCode = query.get("code");

    if (userCode) {
      setCode(userCode);
    }
  }, [location.search, navigate, code]);

  return code;
};
