import { Typography } from "@mui/material";
import { ListingDTO } from "dtos/ListingDTO";
import React from "react";

interface DetailedRepoListingProps {
  listing: ListingDTO;
}

const DetailedRepoListing: React.FC<DetailedRepoListingProps> = ({
  listing,
}) => {
  return (
    <>
      {/* Responsive font size based on screen size */}
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          fontSize: {
            xs: "1.5rem", // Extra small devices
            sm: "2rem", // Small devices
            md: "2.5rem", // Medium devices
            lg: "3rem", // Large devices
          },
        }}
      >
        {listing?.repo}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          textAlign: "center",
          fontSize: {
            xs: "1rem", // Extra small devices
            sm: "1.5rem", // Small devices
            md: "1.75rem", // Medium devices
            lg: "2rem", // Large devices
          },
        }}
      >
        by {listing?.username}
      </Typography>
      <Typography>{listing?.description}</Typography>
      <Typography>Price: ${listing?.price}</Typography>
    </>
  );
};

export default DetailedRepoListing;
